import React, { useState, useEffect } from 'react';
import axios from 'axios';
import withRoleAccess from '../hoc/withRoleAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGraduate, faChalkboardTeacher, faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import { BarChart, CartesianGrid, XAxis, YAxis, Legend, Bar, ResponsiveContainer, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, PieChart, Pie, Cell, Tooltip } from 'recharts'; 
import './Dashboard.css';

function AdminDashboard() {
  const { t, i18n } = useTranslation();
  const [stats, setStats] = useState([]);
  const [todayClasses, setTodayClasses] = useState([]);
  const [classGroups, setClassGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;

  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hr')}`;
  };

 // Mocked data for GED vs IELTS student comparison
 const gedIeltsData = [
  { subject: 'GED', students: 120 },
  { subject: 'IELTS', students: 75 },
];

// Mocked data for SAT student performance (Reading, Math, Writing)
const satPerformanceData = [
  { subject: 'Reading', score: 650 },
  { subject: 'Math', score: 700 },
  { subject: 'Writing', score: 640 },
];

// Mocked data for IGCSE grades distribution
const igcseGradeData = [
  { grade: 'A*', students: 40 },
  { grade: 'A', students: 30 },
  { grade: 'B', students: 20 },
  { grade: 'C', students: 10 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };

        const [studentsResponse, teachersResponse, classesResponse] = await Promise.all([
          axios.get(`${API_URL}/students/count`, { headers }),
          axios.get(`${API_URL}/teachers/count`, { headers }),
          axios.get(`${API_URL}/class-groups/count`, { headers })
        ]);

        setStats({
          totalStudents: studentsResponse.data.total_students,
          totalTeachers: teachersResponse.data.total_teachers,
          totalClasses: classesResponse.data.total_classes,
          totalSales: 140000, // Replace with real sales data if available
        });
      } catch (error) {
        console.error('Error fetching stats:', error);
        setError(t('fetchStatsError'));
      }
    };

    const fetchTodayClasses = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
  
        
        const response = await axios.get(`${API_URL}/classes`, { headers });
    
    
        const today = new Date();
        const todayString = today.toISOString().split('T')[0]; 

    
        // Filter classes for today
        const todayClasses = response.data.filter(cls => {
          const classDate = new Date(cls.date).toISOString().split('T')[0]; 
          return classDate === todayString;
        });
    
        const sortedTodayClasses = todayClasses.sort((a, b) => {
          const [hoursA, minutesA] = a.schedule_time.split(':');
          const [hoursB, minutesB] = b.schedule_time.split(':');
          const dateA = new Date(a.date); 
          const dateB = new Date(b.date); 
          dateA.setHours(hoursA);
          dateA.setMinutes(minutesA);
          dateB.setHours(hoursB);
          dateB.setMinutes(minutesB);
          return dateA - dateB;
        });
        
    
        const formattedClasses = sortedTodayClasses.map(cls => ({
          classid: cls.classid,
          class_code: cls.class_code,
          subject_name: cls.subject_name,
          teacher_nickname: cls.nickname,
          schedule_time: cls.schedule_time,
          schedule_hour: cls.schedule_hour,
          date: cls.date
        }));
    
        setTodayClasses(formattedClasses);
      } catch (error) {
        console.error('Error fetching today\'s classes:', error);
        setError(t('fetchTodayClassesError'));
      }
    };
    
    const fetchNewCourses = async () => {
      try {
        const token = localStorage.getItem('token');
        const headers = { Authorization: `Bearer ${token}` };
        const [classGroupsResponse, classesResponse] = await Promise.all([
          axios.get(`${API_URL}/class-groups?type=group`, { headers }),
          axios.get(`${API_URL}/classes`, { headers })
        ]);
    
        const classGroups = classGroupsResponse.data;
        const classes = classesResponse.data;
    
        const aggregatedClassGroups = classGroups.map(group => {
          const groupClasses = classes.filter(c => c.groupid === group.groupid);
    
          const start_date = groupClasses.length > 0 ? groupClasses[0].date : null;
          const end_date = groupClasses.length > 0 ? groupClasses[groupClasses.length - 1].date : null;
    
          const subjectNames = [...new Set(groupClasses.map(c => c.subject_name))].join(', ');
          const teacherNames = [...new Set(groupClasses.map(c => c.nickname))].join(', ');
    
          return { ...group, start_date, end_date, subject_name: subjectNames, nickname: teacherNames };
        });
    
        const now = new Date();
    
        // Filter out groups with a passed start_date
        const upcomingCourses = aggregatedClassGroups.filter(group => {
          return group.start_date && new Date(group.start_date) >= now;
        });
    
        // Sort by start_date, closest date first
        const sortedCourses = upcomingCourses.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
    
        setClassGroups(sortedCourses);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching class groups:', error);
        setError(t('fetchClassGroupsError'));
        setLoading(false);
      }
    };    
    
    fetchStats();
    fetchTodayClasses();
    fetchNewCourses();
    
  }, [API_URL, t]);

  if (error) return <p>{error}</p>;

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric'};
    return date.toLocaleDateString(i18n.language, options);
  };

  const formatScheduleHour = (scheduleHourString) => {
    const [hours, minutes] = scheduleHourString.split(' ').map((value) => parseInt(value));
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  };

  const getTodayClasses = () => {
    const today = new Date();
    const todayString = today.toISOString().split('T')[0]; 

  
    return todayClasses.filter(cls => {
      const classDate = new Date(cls.date).toISOString().split('T')[0]; 
      return classDate === todayString;
    });
  };

  const calculateEndTime = (scheduleTime, scheduleHour) => {
    const [hours, minutes] = scheduleTime.split(':').map(Number);
    let duration = 0;
    
    if (typeof scheduleHour === 'string') {
      if (scheduleHour.includes(':')) {
        const [durationHours, durationMinutes] = scheduleHour.split(':').map(Number);
        duration = (durationHours * 60) + durationMinutes;
      } else {
        duration = parseFloat(scheduleHour) * 60;
      }
    } else if (typeof scheduleHour === 'number') {
      duration = scheduleHour * 60;
    }
    
    if (isNaN(duration)) {
      console.error('Invalid scheduleHour:', scheduleHour);
      return 'N/A';
    }
    
    const endDate = new Date(2000, 0, 1, hours, minutes + duration);
    const endHours = String(endDate.getHours()).padStart(2, '0');
    const endMinutes = String(endDate.getMinutes()).padStart(2, '0');
    
    return `${endHours}:${endMinutes}`;
  };
  

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="admin-dashboard-container">
      <div className="stats-container">
        <div className="stat-box">
          <div className="icon-and-text">
            <div className="icon-circle">
              <FontAwesomeIcon icon={faUserGraduate} className="fa-icon" />
            </div>
            <div className="text-content">
              <h3>{t('totalStudents')}</h3>
              <p>{stats.totalStudents}</p>
              <div className="percentage-change">
                <span className="positive-change">+10%</span> {t('thanLastMonth')}
              </div>
            </div>
          </div>
        </div>
        <div className="stat-box">
          <div className="icon-and-text">
            <div className="icon-circle">
              <FontAwesomeIcon icon={faChalkboardTeacher} className="fa-icon" />
            </div>
            <div className="text-content">
              <h3>{t('totalTeachers')}</h3>
              <p>{stats.totalTeachers}</p>
              <div className="percentage-change">
                <span className="negative-change">-0.5%</span> {t('thanLastMonth')}
              </div>
            </div>
          </div>
        </div>
        <div className="stat-box">
          <div className="icon-and-text">
            <div className="icon-circle">
              <FontAwesomeIcon icon={faBookOpen} className="fa-icon" />
            </div>
            <div className="text-content">
              <h3>{t('activeClasses')}</h3>
              <p>{stats.totalClasses}</p>
              <div className="percentage-change">
                <span className="negative-change">-0.5%</span> {t('thanLastMonth')}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="test-analysis-container">
          <Row gutter={[16, 16]} style={{ marginBottom: '24px' }}>
            <Col xs={24} sm={24} md={8}>
              <div className="test-analysis-chart">
                <h3>{t('GED vs IELTS Student Count')}</h3>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={gedIeltsData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="subject" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="students" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Col>

            {/* SAT Performance Radar Chart */}
            <Col xs={24} sm={24} md={8}>
              <div className="test-analysis-chart">
                <h3>{t('SAT Performance by Subject')}</h3>
                <ResponsiveContainer width="100%" height={300}>
                  <RadarChart cx="50%" cy="50%" outerRadius="80%" data={satPerformanceData}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="subject" />
                    <PolarRadiusAxis />
                    <Radar name="SAT" dataKey="score" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
                    <Tooltip />
                  </RadarChart>
                </ResponsiveContainer>
              </div>
            </Col>

            {/* IGCSE Grade Distribution Pie Chart */}
            <Col xs={24} sm={24} md={8}>
              <div className="test-analysis-chart">
                <h3>{t('IGCSE Grade Distribution')}</h3>
                <ResponsiveContainer width="100%" height={300}>
                  <PieChart>
                    <Pie
                      data={igcseGradeData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={({ name }) => name}
                      outerRadius={80}
                      dataKey="students"
                    >
                      {igcseGradeData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Col>
          </Row>
        </div>
        <div className="classes-calendar-container">
        <div className="list-box">
            <h2>Today Classes</h2>
            {getTodayClasses().length === 0 ? (
              <p>{t('noClassesToday')}</p>
            ) : (
              <div className="classes-cards">
                {getTodayClasses().map((classItem) => {
                  const endTime = calculateEndTime(classItem.schedule_time, classItem.schedule_hour);

                  return (
                    <div key={classItem.classid} className="admin-class-card">
                      <div className="admin-class-card-header">
                        <span className="class-date">{classItem.subject_name}</span>
                        <span className="class-time">{formatTime(classItem.schedule_time)} - {endTime}</span>
                      </div>
                      <div className="admin-class-card-body">
                        <div className="admin-class-info">
                          <strong>{t('course')}:</strong> {classItem.class_code}
                        </div>
                        <div className="admin-class-info">
                        <strong>{t('teacher')}:</strong> {classItem.teacher_nickname}
                      </div>
                        <div className="admin-class-info">
                          <strong>{t('hours')}:</strong> {formatScheduleHour(classItem.schedule_hour)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        <div className="list-box">
  <h2>{t('New Class Groups')}</h2>
  <div className="classes-cards">
    {classGroups
      .filter(cls => !cls.isprivate) // Filter out private classes
      .map((cls, index) => (
        <div key={index} className="admin-class-card">
          <div className="admin-class-card-header">
            <span className="class-code">{cls.class_code}</span>
            <span className="course-date">{`${formatDate(cls.start_date)} - ${formatDate(cls.end_date)}`}</span>
          </div>
          <div className="admin-class-card-body">
            <div className="admin-class-info">
              <strong>{t('subjects')}:</strong> {cls.subject_name}
            </div>
            <div className="admin-class-info">
              <strong>{t('teachers')}:</strong> {cls.nickname}
            </div>
            <div className="admin-class-info">
              <strong>{t('totalHours')}:</strong> {formatMinutesToHours(cls.total_hours)}
            </div>
            <div className="admin-class-info">
              <strong>{t('registerCapacity')}:</strong> {cls.register_capacity}
            </div>
          </div>
        </div>
      ))}
  </div>
</div>
      </div>
    </div>
  );
}

export default withRoleAccess(AdminDashboard, ['admin', 'superadmin']);
