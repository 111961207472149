import axios from 'axios';

const getUserIdFromToken = () => {
  const token = localStorage.getItem('token');
  if (token) {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      return payload.userId; // Ensure this matches the user ID field in your token payload
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  } else {
    console.error('Token not found');
    return null;
  }
};

export const refreshToken = async () => {
  const refreshTokenValue = localStorage.getItem('refreshToken');
  if (!refreshTokenValue) {
    throw new Error('No refresh token available'); // This error is being caught and logged.
  }

  try {
    const response = await axios.post('http://localhost:3001/api/refresh-token', {
      refreshToken: refreshTokenValue,
    });
    const { accessToken } = response.data;
    localStorage.setItem('token', accessToken); // Save the new access token.
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    return accessToken;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

export const isTokenExpiredError = (error) => {
  return error.response && error.response.status === 401 && error.response.data.message === 'Token expired';
};

export default getUserIdFromToken;
