import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import withRoleAccess from '../../hoc/withRoleAccess';
import '../Dashboard.css';

const SubjectModal = ({ isOpen, onClose, onSave, subjectData }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        subjectgroup: '',
        code: '',
        year: '',
        title: '',
        level: '',
        specification: '',
        description: '',
        image_url: '',
        language: [] // New field for language
    });

    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        if (subjectData) {
            setFormData(subjectData);
        }
    }, [subjectData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'language') {
            const selectedLanguages = formData.language.includes(value)
                ? formData.language.filter(lang => lang !== value)
                : [...formData.language, value];
            setFormData(prevData => ({
                ...prevData,
                language: selectedLanguages
            }));
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        await onSave(formData);
        setShowConfirmation(true);
    };

    const handleConfirmationClose = () => {
        setShowConfirmation(false);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="subject-modal-backdrop">
            <div className="subject-modal">
                <span className="modal-close-icon" onClick={onClose}>&times;</span>
                <h2>{subjectData ? t('editSubject') : t('createSubject')}</h2>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label>{t('subjectGroup')}:</label>
                        <input type="text" name="subjectgroup" value={formData.subjectgroup} onChange={handleChange} />
                    </div>
                    <div>
                        <label>{t('code')}:</label>
                        <input type="text" name="code" value={formData.code} onChange={handleChange} />
                    </div>
                    <div>
                        <label>{t('year')}:</label>
                        <input type="text" name="year" value={formData.year} onChange={handleChange} />
                    </div>
                    <div>
                        <label>{t('subjecttitle')}:</label>
                        <input type="text" name="title" value={formData.title} onChange={handleChange} />
                    </div>
                    <div>
                        <label>{t('level')}:</label>
                        <input type="text" name="level" value={formData.level} onChange={handleChange} />
                    </div>
                    <div>
                        <label>{t('specification')}:</label>
                        <input type="text" name="specification" value={formData.specification} onChange={handleChange} />
                    </div>
                    <div>
                        <label>{t('description')}:</label>
                        <input type="text" name="description" value={formData.description} onChange={handleChange} />
                    </div>
                    <div>
                        <label>{t('imageUrl')}:</label>
                        <input type="text" name="image_url" value={formData.image_url} onChange={handleChange} />
                    </div>
                    <div>
                        <label>{t('languages')}:</label>
                        <div>
                            <label>
                                <input
                                    type="checkbox"
                                    name="language"
                                    value="Thai"
                                    checked={formData.language.includes('Thai')}
                                    onChange={handleChange}
                                />
                                Thai
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    name="language"
                                    value="English"
                                    checked={formData.language.includes('English')}
                                    onChange={handleChange}
                                />
                                English
                            </label>
                        </div>
                    </div>
                    <div className="modal-actions">
                        <button type="submit" className="submit-button">{t('save')}</button>
                    </div>
                </form>
                
            </div>

            {showConfirmation && (
                <div className="confirmation-modal-overlay">
                    <div className="confirmation-modal-content">
                        <p>{t('changesSaved')}</p>
                        <button onClick={handleConfirmationClose}>{t('ok')}</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default withRoleAccess(SubjectModal, ['admin', 'superadmin']);
