import React, { useState, useContext } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import ChatList from './ChatList'; 
import ChatWindow from './ChatWindow'; 
import withRoleAccess from '../../hoc/withRoleAccess';
import { AuthContext } from '../../components/Auth/AuthContext';
import './Chat.css';

const ChatPage = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const { permissions } = useContext(AuthContext);
  const { userId } = permissions;



  const handleUnreadCountChange = (newUnreadCount) => {
    setUnreadCount(newUnreadCount);
  };

  return (
    <div>
      <Sidebar unreadCount={unreadCount} />
      <Header />
      <div className="chat-page">
        <ChatList 
          onSelectUser={setSelectedUser} 
          onUnreadCountChange={handleUnreadCountChange}
          currentUserId={userId}
        />
        {selectedUser ? (
          <ChatWindow currentUserId={userId} selectedUserId={selectedUser} />
        ) : (
          <div className="chat-placeholder">Select a user to start chatting</div>
        )}
      </div>
    </div>
  );
};

export default withRoleAccess(ChatPage, ['admin', 'superadmin', 'teacher']);