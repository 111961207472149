import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './InventoryPage.css'; 
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from '../../components/Modal';
import { useTranslation } from 'react-i18next';

const CurrentInventory = () => {
    const { t } = useTranslation();
    const [inventory, setInventory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [adjustments, setAdjustments] = useState({}); 
    const [selectedInventoryId, setSelectedInventoryId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editInventoryData, setEditInventoryData] = useState({});

    const API_URL = process.env.REACT_APP_API_URL;

    const fetchInventory = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_URL}/inventory`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            const currentQuantityResponse = await axios.get(`${API_URL}/inventory/current-quantity`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const inventoryWithQuantities = response.data.map((item) => {
                const quantityData = currentQuantityResponse.data.find(q => q.inventory_id === item.inventory_id);
                return {
                    ...item,
                    current_quantity: quantityData ? quantityData.current_quantity : 0
                };
            });

            setInventory(inventoryWithQuantities);
            setLoading(false);
        } catch (err) {
            setError('Failed to fetch inventory data.');
            setLoading(false);
        }
    }, [API_URL]);

    useEffect(() => {
        fetchInventory();
    }, [fetchInventory]);

    const handleQuantityChange = (value) => {
        setAdjustments((prevAdjustments) => ({
            ...prevAdjustments,
            [selectedInventoryId]: value
        }));
    };

    const handleUpdateQuantity = async () => {
        const quantity_change = adjustments[selectedInventoryId];
        if (!quantity_change) {
            alert("Please enter a quantity to adjust.");
            return;
        }

        try {
            const token = localStorage.getItem('token');
            await axios.post(`${API_URL}/inventory/log`, {
                inventory_id: selectedInventoryId,
                quantity_change: parseInt(quantity_change, 10),
                change_reason: 'Admin'
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setIsModalOpen(false);
            fetchInventory();
            confirmAlert({
                title: 'Success',
                message: 'Inventory quantity updated successfully!',
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {}
                    }
                ]
            });
        } catch (error) {
            console.error('Error adjusting quantity:', error);
        }
    };

    const openModal = (id) => {
        setSelectedInventoryId(id);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedInventoryId(null);
        setIsModalOpen(false);
    };

    const openEditModal = (item) => {
        setSelectedInventoryId(item.inventory_id);
        setEditInventoryData({
            product_id: item.product_id,
            item_name: item.item_name,
            item_description: item.item_description,
            category: item.category,
            price_per_unit: item.price_per_unit
        });
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setSelectedInventoryId(null);
        setEditInventoryData({});
        setIsEditModalOpen(false);
    };

    const handleEditInventoryChange = (e) => {
        const { name, value } = e.target;
        setEditInventoryData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleEditInventorySubmit = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(`${API_URL}/inventory/${selectedInventoryId}`, editInventoryData, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setIsEditModalOpen(false);
            fetchInventory();
            confirmAlert({
                title: 'Success',
                message: 'Inventory item updated successfully!',
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {}
                    }
                ]
            });
        } catch (error) {
            console.error('Error updating inventory:', error);
        }
    };

    const handleStatusChange = async (id, newStatus) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: `Are you sure you want to change the status to ${newStatus}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        try {
                            const token = localStorage.getItem('token');
                            await axios.put(`${API_URL}/inventory/${id}/status`, { status: newStatus }, {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            });
                            fetchInventory();
                        } catch (error) {
                            console.error('Error changing status:', error);
                        }
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="main-content">
            <Sidebar />
            <Header />
            <div>
                <h1>{t('Current Inventory')}</h1>
                <table className="inventory-table">
                    <thead>
                        <tr>
                            <th>{t('productid')}</th>
                            <th>{t('itemname')}</th>
                            <th>{t('description')}</th>
                            <th>{t('category')}</th>
                            <th>{t('priceperunit')}</th>
                            <th>{t('currentquantity')}</th>
                            <th>{t('status')}</th>
                            <th>{t('adjust')}</th>
                            <th>{t('edit')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {inventory.map((item) => (
                            <tr key={item.inventory_id}>
                                <td>{item.product_id}</td>
                                <td>{item.item_name}</td>
                                <td>{item.item_description}</td>
                                <td>{item.category}</td>
                                <td>{item.price_per_unit}</td>
                                <td>{item.current_quantity}</td>
                                <td>
                                    <select
                                        value={item.status || ''}
                                        onChange={e => handleStatusChange(item.inventory_id, e.target.value)}
                                        className={item.status === 'active' ? 'select-active' : 'select-inactive'}
                                    >
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                    </select>
                                </td>
                                <td>
                                    <button className="adjust-button" onClick={() => openModal(item.inventory_id)}>Adjust Inventory</button>
                                </td>
                                <td>
                                    <button className="edit-button" onClick={() => openEditModal(item)}><FontAwesomeIcon icon={faEdit} /></button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Adjust Quantity Modal */}
            {isModalOpen && (
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                    <h2>Adjust Inventory Quantity</h2>
                    <input
                        type="number"
                        value={adjustments[selectedInventoryId] || ''}
                        onChange={(e) => handleQuantityChange(e.target.value)}
                        placeholder="Enter quantity"
                    />
                    <button onClick={handleUpdateQuantity}>Update Quantity</button>
                </Modal>
            )}

            {/* Edit Inventory Modal */}
            {isEditModalOpen && (
                <Modal isOpen={isEditModalOpen} onClose={closeEditModal}>
                    <h2>Edit Inventory Item</h2>
                    <label>Product ID:</label>
                    <input
                        type="text"
                        name="product_id"
                        value={editInventoryData.product_id || ''}
                        onChange={handleEditInventoryChange}
                    />
                    <label>Item Name:</label>
                    <input
                        type="text"
                        name="item_name"
                        value={editInventoryData.item_name || ''}
                        onChange={handleEditInventoryChange}
                    />
                    <label>Description:</label>
                    <input
                        type="text"
                        name="item_description"
                        value={editInventoryData.item_description || ''}
                        onChange={handleEditInventoryChange}
                    />
                    <label>Category:</label>
                    <input
                        type="text"
                        name="category"
                        value={editInventoryData.category || ''}
                        onChange={handleEditInventoryChange}
                    />
                    <label>Price per Unit:</label>
                    <input
                        type="number" disabled
                        name="price_per_unit"
                        value={editInventoryData.price_per_unit || ''}
                        onChange={handleEditInventoryChange}
                    />
                    <button onClick={handleEditInventorySubmit}>Save Changes</button>
                </Modal>
            )}
        </div>
    );
};

export default withRoleAccess(CurrentInventory, ['superadmin', 'admin']);
