import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import '../Dashboard.css';
import withRoleAccess from '../../hoc/withRoleAccess';

const EditTeacherModal = ({ isOpen, onClose, onSave, teacherId }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        phone: '',
        address: '',
        email: '',
        nickname: '',
        photourl: '',
        bachelor_degree: '',
        specialize_subjects: '',
        schedule: '',
        isparttime: false,
        bachelor_school: '',
        master_degree: '',
        master_school: '',
        doctoral_degree: '',
        doctoral_school: '',
        hoursrate: '',
        color: '',
        language: '',
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchTeacherData = async () => {
            if (!teacherId) return;
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${API_URL}/teachers/${teacherId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setFormData(response.data);
            } catch (error) {
                console.error('Error fetching teacher data:', error);
                setError(t('fetchTeacherError'));
            } finally {
                setLoading(false);
            }
        };

        if (isOpen) {
            fetchTeacherData();
        }
    }, [teacherId, isOpen, API_URL, t]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            
            // Exclude color and language from being sent
            const { color, language, ...formDataToSubmit } = formData;
            
            // Send the rest of the form data (without color and language) to the API
            await axios.put(`${API_URL}/teachers/${teacherId}`, formDataToSubmit, {
                headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
            });
            
            // Call onSave to update the teacher in the parent component with the correct color
            onSave({ ...formDataToSubmit, color });  // Keep the color in the state
            
            onClose();  // Close the modal after saving
        } catch (error) {
            console.error('Error updating teacher:', error);
            setError(t('updateTeacherError'));
        }
    };
    

    if (!isOpen) return null;

    return (
        <div className="panel-overlay">
            <div className="modal-content">
                <span className="modal-close-icon" onClick={onClose}>&times;</span>
                <div className="modal-header">
                    <h2>{t('editTeacher')}</h2>
                </div>
                {loading ? (
                    <p>{t('loading')}</p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('firstName')}:</label>
                                <input type="text" name="firstname" value={formData.firstname} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('lastName')}:</label>
                                <input type="text" name="lastname" value={formData.lastname} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('email')}:</label>
                                <input type="email" name="email" value={formData.email} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('nickname')}:</label>
                                <input type="text" name="nickname" value={formData.nickname} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                        <div className="form-row">
                        <div className="form-group">
                                <label>{t('color')}:</label>
                                <input 
                                    type="text" 
                                    name="color" 
                                    value={formData.color} 
                                    readOnly 
                                    style={{ backgroundColor: formData.color, color: '#fff', width: '100%', padding: '10px' }} // Apply color as background
                                />
                                </div>
                            <div className="form-group">
                                <label>{t('language')}:</label>
                                {/* Display languages as comma-separated text */}
                                <input 
                                    type="text" 
                                    name="language" 
                                    value={Array.isArray(formData.language) ? formData.language.join(', ') : formData.language} 
                                    readOnly
                                />
                            </div>
                        </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('photoURL')}:</label>
                                <input type="text" name="photourl" value={formData.photourl} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('hoursRate')}:</label>
                                <input type="number" name="hoursrate" value={formData.hoursrate} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('specializeSubjects')}:</label>
                                <input type="text" name="specialize_subjects" value={formData.specialize_subjects} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('about')}:</label>
                                <input type="text" name="schedule" value={formData.schedule} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('bachelorDegree')}:</label>
                                <input type="text" name="bachelor_degree" value={formData.bachelor_degree} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('bachelorSchool')}:</label>
                                <input type="text" name="bachelor_school" value={formData.bachelor_school} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('masterDegree')}:</label>
                                <input type="text" name="master_degree" value={formData.master_degree} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('masterSchool')}:</label>
                                <input type="text" name="master_school" value={formData.master_school} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('doctoralDegree')}:</label>
                                <input type="text" name="doctoral_degree" value={formData.doctoral_degree} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('doctoralSchool')}:</label>
                                <input type="text" name="doctoral_school" value={formData.doctoral_school} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label >
                                    <input 
                                        type="checkbox" 
                                        name="isparttime" 
                                        checked={formData.isparttime} 
                                        onChange={handleChange} 
                                        className="custom-checkbox-input"
                                    />
                                    <span className="checkmark"></span>
                                    {t('partTime')}
                                </label>
                            </div>
                        </div>
                        <div className="modal-actions">
                            <button type="button" className="cancel-button" onClick={onClose}>{t('cancel')}</button>
                            <button type="submit" className="submit-button">{t('save')}</button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default withRoleAccess(EditTeacherModal, ['admin', 'superadmin']);
