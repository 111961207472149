import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './MatchTeacherSubject.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

const MatchTeacherSubject = () => {
    const { t } = useTranslation();
    const [teachers, setTeachers] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [matches, setMatches] = useState([]);
    const [filteredTeachers, setFilteredTeachers] = useState([]);
    const [message, setMessage] = useState('');
    const [activeTab, setActiveTab] = useState('THAI');

    // Separate states for the "Matched Teachers" box
    const [matchedSelectedSubject, setMatchedSelectedSubject] = useState('');
    const [matchedSelectedLanguage, setMatchedSelectedLanguage] = useState('');

    const API_URL = process.env.REACT_APP_API_URL;

    const fetchData = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setMessage('No token found. Please log in.');
                return;
            }

            const [teachersResponse, subjectsResponse, matchesResponse] = await Promise.all([
                axios.get(`${API_URL}/teachers`, { headers: { Authorization: `Bearer ${token}` } }),
                axios.get(`${API_URL}/subjects`, { headers: { Authorization: `Bearer ${token}` } }),
                axios.get(`${API_URL}/match/match-teacher-to-subject`, { headers: { Authorization: `Bearer ${token}` } }),
            ]);

            setTeachers(teachersResponse.data);
            setSubjects(subjectsResponse.data);
            setMatches(matchesResponse.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            setMessage('An error occurred while fetching data.');
        }
    }, [API_URL]);

    useEffect(() => {
        fetchData(); // Initial data fetch
    }, [fetchData]);

    const handleMatch = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setMessage('No token found. Please log in.');
                return;
            }

            await axios.post(`${API_URL}/match/match-teacher-to-subject`, {
                teacherId: selectedTeacher,
                subjectId: selectedSubject,
                language: selectedLanguage
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            // Show confirmation alert after successful match
            confirmAlert({
                title: t('Match Success'),
                message: t('Match created successfully!'),
                buttons: [
                    {
                        label: t('ok'),
                        onClick: () => fetchData() // Fetch data again to update the UI
                    }
                ]
            });

        } catch (error) {
            console.error('Error creating match:', error);
            setMessage('Error creating match: ' + (error.response ? error.response.data.message : 'Server error'));
        }
    };

    const handleDeleteMatch = async (matchId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`${API_URL}/match/match-teacher-to-subject/${matchId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            confirmAlert({
                title: t('Delete Success'),
                message: t('Match deleted successfully!'),
                buttons: [
                    {
                        label: t('ok'),
                        onClick: () => fetchData()
                    }
                ]
            });
        } catch (error) {
            console.error('Error deleting match:', error);
            setMessage('Error deleting match: ' + (error.response ? error.response.data.message : 'Server error'));
        }
    };

    useEffect(() => {
        const filterTeachers = () => {
            if (matchedSelectedSubject && matchedSelectedLanguage) {
                const filtered = matches.filter(match =>
                    match.subject_id === parseInt(matchedSelectedSubject) &&
                    match.language === matchedSelectedLanguage
                ).map(match => ({
                    firstname: match.teacher_firstname,
                    lastname: match.teacher_lastname,
                    nickname: match.teacher_nickname
                }));
                setFilteredTeachers(filtered);
            } else {
                setFilteredTeachers([]);
            }
        };

        filterTeachers();
    }, [matchedSelectedSubject, matchedSelectedLanguage, matches]);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const sortedSubjects = useMemo(() => 
        [...subjects].sort((a, b) => 
          a.title.localeCompare(b.title, undefined, { sensitivity: 'base' })
        ),
        [subjects]
      );

    return (
        <div>
            <h2>{t('matchTeacherToSubject')}</h2>
            <div className="match-container">
                {/* Match Teacher to Subject Box */}
                <div className="match-box">
                    <h3>{t('matchingTeachers')}</h3>
                    <div>
                        <label>{t('selectTeacher')}:</label>
                        <select value={selectedTeacher} onChange={(e) => setSelectedTeacher(e.target.value)}>
                            <option value="">{t('selectATeacher')}</option>
                            {teachers.map(teacher => (
                                <option key={teacher.userid} value={teacher.userid}>
                                    {teacher.nickname} {teacher.firstname} {teacher.lastname} 
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label>{t('selectSubject')}:</label>
                        <select value={selectedSubject} onChange={(e) => setSelectedSubject(e.target.value)}>
                            <option value="">{t('selectASubject')}</option>
                            {subjects.map(subject => (
                                <option key={subject.subjectid} value={subject.subjectid}>
                                    {subject.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label>{t('selectLanguage')}</label>
                        <select value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
                            <option value="">{t('selectALanguage')}</option>
                            <option value="Thai">{t('thai')}</option>
                            <option value="English">{t('english')}</option>
                        </select>
                    </div>
                    <button onClick={handleMatch}>{t('createMatch')}</button>
                    {message && <p>{message}</p>}
                </div>

                {/* Matched Teachers Box */}
                <div className="matched-box">
                    <h3>{t('matchedTeachers')}</h3>
                    <div>
                        <label>{t('selectSubject')}:</label>
                        <select value={matchedSelectedSubject} onChange={(e) => setMatchedSelectedSubject(e.target.value)}>
                            <option value="">{t('selectASubject')}</option>
                            {subjects.map(subject => (
                                <option key={subject.subjectid} value={subject.subjectid}>
                                    {subject.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label>{t('selectLanguage')}</label>
                        <select value={matchedSelectedLanguage} onChange={(e) => setMatchedSelectedLanguage(e.target.value)}>
                            <option value="">{t('selectALanguage')}</option>
                            <option value="Thai">{t('thai')}</option>
                            <option value="English">{t('english')}</option>
                        </select>
                    </div>
                    <div>
                        {filteredTeachers.length > 0 ? (
                            <ul>
                                {filteredTeachers.map((teacher, index) => (
                                    <li key={index}> {teacher.nickname} {teacher.firstname} {teacher.lastname}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>{t('noTeachersFound')}</p>
                        )}
                    </div>
                </div>
            </div>
            <div>
                {/* Tab Buttons */}

            <div className="tab-container-language">
                <button onClick={() => handleTabChange('THAI')} className={activeTab === 'THAI' ? 'active' : ''}>
                    {t('thai')}
                </button>
                <button onClick={() => handleTabChange('ENGLISH')} className={activeTab === 'ENGLISH' ? 'active' : ''}>
                    {t('english')}
                </button>
            </div>

            <div className="grid-container-match">
                    {sortedSubjects.map(subject => {
                        const subjectMatches = matches.filter(match => {
                        const matchSubjectId = match.subject_id.toString();
                        const currentSubjectId = subject.subjectid.toString();
                        const matchLanguage = match.language.toLowerCase();
                        const currentTab = activeTab.toLowerCase();
                        return matchSubjectId === currentSubjectId && matchLanguage === currentTab;
                        });
                        return (
                        <div key={subject.subjectid} className="grid-item-match">
                            <h4>{subject.title}</h4>
                            <ul>
                            {subjectMatches.map(match => (
                                <li key={match.id}>{match.teacher_nickname} {match.teacher_firstname} {match.teacher_lastname}</li>
                            ))}
                            </ul>
                        </div>
                        );
                    })}
                    </div>

            <div className="match-table">
                    <h3>{t('Manage Matched Teachers')}</h3>
                    <table className="matched-table">
                        <thead>
                            <tr>
                                <th>{t('teacher')}</th>
                                <th>{t('subject')}</th>
                                <th>{t('language')}</th>
                                <th>{t('actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {matches.map((match) => (
                                <tr key={match.id}>
                                    <td>{match.teacher_nickname} {match.teacher_firstname} {match.teacher_lastname} </td>
                                    <td>{match.subject_title}</td>
                                    <td>{match.language}</td>
                                    <td>
                                        <button className="delete-button" onClick={() => handleDeleteMatch(match.id)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
        </div>
        </div>
    );
};

export default withRoleAccess(MatchTeacherSubject, ['superadmin', 'admin']);
