import React, { useState, useEffect } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import './Chat.css';
import getUserIdFromToken from '../../Utils/authUtils'; // Import the utility function

const ChatList = ({ onSelectUser, onUnreadCountChange }) => {
  const [users, setUsers] = useState([]);
  const [activeUserId, setActiveUserId] = useState(null); // Track the active chat item
  const API_URL = process.env.REACT_APP_API_URL;

  const currentUserId = getUserIdFromToken(); // Fetch the current user ID from the token

  

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/chat`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        // Sort users: first by unread count (descending), then by last message time (descending)
        let sortedUsers = response.data.filter(user => user.userid !== currentUserId);
  
        sortedUsers.sort((a, b) => {
          // First, sort by unread count (users with unread messages at the top)
          if (b.unread_count !== a.unread_count) {
            return b.unread_count - a.unread_count;
          }
          // Then, sort by last message time (most recent at the top)
          const dateA = new Date(a.last_message_time).getTime();
          const dateB = new Date(b.last_message_time).getTime();
          return dateB - dateA;
        });
  
        // Log the sorted user data
        sortedUsers.forEach(user => {
        });
  
        setUsers(sortedUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
  
    fetchUsers();
  }, [API_URL, currentUserId]);  
  

  const getRoleColor = (role) => {
    switch (role) {
      case 'superadmin':
        return '#1a73e8'; 
      case 'admin':
        return '#FF9800'; 
      case 'teacher':
        return '#33673b'; 
      default:
        return '#9E9E9E'; 
    }
  };

  const formatLastSignedIn = (timestamp) => {
    if (!timestamp) return 'Never';
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const isUserOnline = (lastSignedIn) => {
    if (!lastSignedIn) return false;
    const lastOnlineDate = new Date(lastSignedIn);
    const currentTime = new Date();
    const diffInMinutes = (currentTime - lastOnlineDate) / (1000 * 60);
    return diffInMinutes <= 60; // Consider the user online if they signed in within the last 60 minutes
  };

  
  const handleUserSelect = async (userId) => {
    setActiveUserId(userId);
    onSelectUser(userId);

    try {
      const token = localStorage.getItem('token');
      await axios.put(`${API_URL}/chat/mark-read/${currentUserId}/${userId}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setUsers(users.map(user => 
        user.userid === userId ? { ...user, unread_count: 0 } : user
      ));

      const newTotalUnreadCount = users.reduce((sum, user) => sum + (user.userid !== userId ? user.unread_count : 0), 0);
      onUnreadCountChange(newTotalUnreadCount);
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };


  return (
    <div className="chat-list">
      {users.map(user => (
        <div 
          key={user.userid} 
          onClick={() => handleUserSelect(user.userid)} 
          className={`chat-item ${user.userid === activeUserId ? 'active' : ''}`} // Add 'active' class if the user is the active user
        >
          <div 
            className="user-circle" 
            style={{ backgroundColor: getRoleColor(user.role) }}
          >
            {user.userid}
          </div>
          <div className="user-chat-info">
            <div className="user-name">
              <span className={`status-indicator ${isUserOnline(user.last_signed_in) ? 'online' : 'offline'}`}></span>
              {user.nickname}
              {user.unread_count > 0 && (
                <span className="unread-dot">{user.unread_count}</span>
              )}
            </div>
            <div className="user-last-online">
              Last online: {formatLastSignedIn(user.last_signed_in)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default withRoleAccess(ChatList, ['admin', 'superadmin', 'teacher']);