import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Pagination, Table, Tag, Typography, Space } from 'antd';

const { Search } = Input;
const { Text } = Typography;

const CalendarTaskList = ({ tasks }) => {
  const { i18n, t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const tasksPerPage = 20;

  // Get today's date (start and end)
  const now = new Date();
  const todayStart = new Date(now.setHours(0, 0, 0, 0));
  const todayEnd = new Date(now.setHours(23, 59, 59, 999));

  // Formatting functions
  const formatDate = (date) => {
    const locale = i18n.language;
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(date).toLocaleDateString(locale, options);
  };

  const formatTime = (date) => {
    const locale = i18n.language;
    const options = { hour: '2-digit', minute: '2-digit', hour12: false };
    return new Date(date).toLocaleTimeString(locale, options);
  };

  const calculateDuration = (start, end) => {
    if (!end) return 'N/A';
    const startTime = new Date(start);
    const endTime = new Date(end);
    const durationInMinutes = (endTime - startTime) / (1000 * 60);
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = Math.round(durationInMinutes % 60);
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  };

  // Status helpers
  const getStatusInfo = (startDate) => {
    const taskDate = new Date(startDate);
    if (taskDate >= todayStart && taskDate <= todayEnd) {
      return { class: 'today', text: t('statusToday'), color: 'red' };
    } else if (taskDate > todayEnd) {
      return { class: 'upcoming', text: t('statusUpcoming'), color: 'orange' };
    } else {
      return { class: 'past', text: t('statusPast'), color: 'blue' };
    }
  };

  // Filter and sort tasks
  const filterTasks = () => {
    const filtered = tasks.filter(task => 
      task.title && task.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Separate future and past tasks
    const futureTasks = filtered
      .filter(task => new Date(task.start) >= todayStart)
      .sort((a, b) => new Date(a.start) - new Date(b.start));

    const pastTasks = filtered
      .filter(task => new Date(task.start) < todayStart)
      .sort((a, b) => new Date(a.start) - new Date(b.start)); // Sort ascending (oldest first)

    return { futureTasks, pastTasks };
  };

  const { futureTasks, pastTasks } = filterTasks();
  
  // Calculate pages
  const pastPages = Math.ceil(pastTasks.length / tasksPerPage);
  const futurePages = Math.ceil(futureTasks.length / tasksPerPage);
  const totalPages = pastPages + futurePages;

  // Set initial page to first page of future tasks
  useEffect(() => {
    if (pastPages > 0) {
      setCurrentPage(pastPages + 1);
    } else {
      setCurrentPage(1);
    }
  }, [pastPages, searchQuery]);

  // Get current page tasks
  const getCurrentPageTasks = () => {
    if (currentPage <= pastPages) {
      // For past event pages - oldest first
      const startIndex = (currentPage - 1) * tasksPerPage;
      return pastTasks.slice(startIndex, startIndex + tasksPerPage);
    } else {
      // Future tasks pages (including today)
      const futurePageIndex = currentPage - pastPages - 1;
      const startIndex = futurePageIndex * tasksPerPage;
      return futureTasks.slice(startIndex, startIndex + tasksPerPage);
    }
  };

  const columns = [
    {
      title: t('dateColumn'),
      dataIndex: 'start',
      render: (start) => formatDate(start),
    },
    {
      title: t('timeColumn'),
      dataIndex: 'start',
      render: (start, task) => `${formatTime(start)} - ${task.end ? formatTime(task.end) : 'N/A'}`,
    },
    {
      title: t('hoursColumn'),
      render: (task) => calculateDuration(task.start, task.end),
    },
    {
      title: t('titleColumn'),
      dataIndex: 'title',
      render: (title) => <Text strong>{title}</Text>,
    },
    {
      title: t('statusColumn'),
      dataIndex: 'start',
      render: (start) => {
        const { text, color } = getStatusInfo(start);
        return <Tag color={color}>{text}</Tag>;
      },
    },
  ];

  // Show page information with date ranges
  const showPageInfo = () => {
    const currentTasks = getCurrentPageTasks();
    if (currentTasks.length === 0) return '';

    const firstDate = formatDate(currentTasks[0]?.start);
    const lastDate = formatDate(currentTasks[currentTasks.length - 1]?.start);

    if (currentPage <= pastPages) {
      return `Past Events (${firstDate} - ${lastDate})`;
    } else {
      return `Current & Future Events (From ${firstDate})`;
    }
  };

  return (
    <div className="task-list-container">
      <Space direction="vertical" style={{ width: '100%' }}>
        <Search
          placeholder={t('searchPlaceholder')}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          allowClear
          style={{ marginBottom: '16px' }}
        />
        <div style={{ marginBottom: '16px' }}>
          <Text type="secondary">{showPageInfo()}</Text>
        </div>
        <Table
          columns={columns}
          dataSource={getCurrentPageTasks()}
          pagination={false}
          rowKey={(task) => task.id}
          locale={{ emptyText: t('noTasks') }}
        />
        <Pagination
          current={currentPage}
          total={(pastTasks.length + futureTasks.length)}
          pageSize={tasksPerPage}
          onChange={(page) => setCurrentPage(page)}
          showSizeChanger={false}
          style={{ textAlign: 'center', marginTop: '16px' }}
        />
      </Space>
    </div>
  );
};

export default CalendarTaskList;