import React, { useState } from "react";
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Calendar from '../../components/Calendar/Calendar';
import CalendarTaskList from '../../components/Calendar/CalendarTaskList';
import './Task.css';

function MyCalendar() {
    const [tasks, setTasks] = useState([]);
    const [classes, setClasses] = useState([]);

    const handleTasksChange = (updatedTasks) => {
        setTasks(updatedTasks);
    };

    const handleClassesChange = (updatedClasses) => {
        setClasses(updatedClasses);
    };

    const combinedEvents = [...tasks, ...classes];

    return (
        <div className="mycalendar-container">
            <Sidebar />
            <Header />
            <div className="calendar-main-content">
                <div className="mycalendar-content">
                    <Calendar onTasksChange={handleTasksChange} onClassesChange={handleClassesChange} />
                </div>
                <div className="task-list-content">
                    <CalendarTaskList tasks={combinedEvents} />
                </div>
            </div>
        </div>
    );
}

export default MyCalendar;
