import React, { useState } from 'react';
import axios from 'axios';
import './InventoryPage.css'; 
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';

const InventoryPage = () => {
    const { t } = useTranslation();
    const [newItem, setNewItem] = useState({
        product_id: '',
        item_name: '',
        item_description: '',
        category: '',
        price_per_unit: 0.0
    });

    const API_URL = process.env.REACT_APP_API_URL; 

    // Function to handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewItem((prevItem) => ({
            ...prevItem,
            [name]: value
        }));
    };

    // Function to add a new inventory item
    const handleAddItem = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token'); 
            await axios.post(`${API_URL}/inventory`, newItem, {
                headers: {
                    Authorization: `Bearer ${token}` 
                }
            });

            // Show confirmation alert
            confirmAlert({
                title: 'Success!',
                message: 'Inventory item added successfully.',
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {
                            // Clear form inputs after successful addition
                            setNewItem({
                                product_id: '',
                                item_name: '',
                                item_description: '',
                                category: '',
                                price_per_unit: 0.0
                            });

                            // re-fetch inventory data or perform any other action
                            // fetchInventory();
                        }
                    }
                ]
            });

        } catch (error) {
            console.error('Error adding inventory item:', error);

            // Show error alert
            confirmAlert({
                title: 'Error',
                message: 'Failed to add inventory item. Please try again.',
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {}
                    }
                ]
            });
        }
    };

    return (
        <div className="main-content">
            <Sidebar />
            <Header />
            <h1>{t('Inventory Management')}</h1>
            <form onSubmit={handleAddItem} className="inventory-form">
                <label htmlFor="product_id">{t('Product ID')}</label>
                <input
                    type="text"
                    name="product_id"
                    id="product_id"
                    value={newItem.product_id}
                    onChange={handleInputChange}
                    placeholder={t('Enter Product ID')}
                    required
                />

                <label htmlFor="item_name">{t('Item Name')}</label>
                <input
                    type="text"
                    name="item_name"
                    id="item_name"
                    value={newItem.item_name}
                    onChange={handleInputChange}
                    placeholder={t('Enter Item Name')}
                    required
                />

                <label htmlFor="item_description">{t('Item Description')}</label>
                <textarea
                    name="item_description"
                    id="item_description"
                    value={newItem.item_description}
                    onChange={handleInputChange}
                    placeholder={t('Enter Item Description')}
                />

                <label htmlFor="category">{t('Category')}</label>
                <select
                    name="category"
                    id="category"
                    value={newItem.category}
                    onChange={handleInputChange}
                    required
                >
                    <option value="" disabled>{t('Select a category')}</option>
                    <option value="Test">{t('TEST')}</option>
                    <option value="IELTS Book">{t('IELTS Book')}</option>
                    <option value="TOEFL Book">{t('TOEFL Book')}</option>
                    <option value="GED Book">{t('GED Book')}</option>
                    <option value="IGCSE Book">{t('IGCSE Book')}</option>
                    <option value="Other Book">{t('Other Book')}</option>
                    <option value="Equipment">{t('Equipment')}</option>
                    <option value="Others">{t('Others')}</option>
                </select>

                <label htmlFor="price_per_unit">{t('Price per Unit')}</label>
                <input
                    type="number"
                    step="0.01"
                    name="price_per_unit"
                    id="price_per_unit"
                    value={newItem.price_per_unit}
                    onChange={handleInputChange}
                    placeholder={t('Enter Price per Unit')}
                    required
                />

                <button type="submit">{t('Add Item')}</button>
            </form>
        </div>
    );
};

export default withRoleAccess(InventoryPage, ['superadmin', 'admin']);
