import React from 'react';
import { useTranslation } from 'react-i18next';
import '../Subject/Subject.css';

const ConfirmationModal = ({ isOpen, message, onClose }) => {
    const { t } = useTranslation();

    if (!isOpen) return null;

    return (
        <div className="confirmation-modal-overlay">
            <div className="confirmation-modal-content">
                <p>{t('successfullySaved')}</p>
                <button onClick={onClose}>{t('ok')}</button>
            </div>
        </div>
    );
};

export default ConfirmationModal;
