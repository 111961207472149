import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import '../common.css';
import PropTypes from 'prop-types';

function SignIn({ setUserData }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const { signIn } = useAuth(); 
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleSignIn = async (e) => {
    e.preventDefault();
    setEmailError('');
    setPasswordError('');

    try {
      const deviceName = navigator.userAgent;
      const response = await fetch(`${API_URL}/auth/signin`, {
        
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          email, 
          password, 
          deviceName 
        }),
      });

      if (response.ok) {
        const responseData = await response.json();

        const { accessToken, refreshToken, userId, role, isTempPassword } = responseData;
      
        // Ensure accessToken is a valid string
        if (typeof accessToken !== 'string' || accessToken.trim() === '') {
          throw new Error('Invalid token received from the server');
        }

        // Store the token and userId in local storage
        localStorage.setItem('token', accessToken); // Make sure to use 'token' to match other parts of your frontend logic
        localStorage.setItem('refreshToken', refreshToken); // Store the refresh token
        localStorage.setItem('userId', userId);
        localStorage.setItem('role', role);
        signIn(accessToken); // Pass accessToken instead of token
        setUserData({ userId, role });

        if (isTempPassword) {
          navigate('/change-password');
        } else {
          navigate('/dashboard');
        }
      } else {
        const errorResponse = await response.json();
        switch (errorResponse.error) {
          case 'UserNotFound':
            setEmailError('The email address is not registered.');
            break;
          case 'InvalidPassword':
            setPasswordError('The password is incorrect.');
            break;
          default:
            setEmailError('Email or password or both are incorrect.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setEmailError('Email or password or both are incorrect.');
    }
  };

  return (
    <div className="auth-container">
      <form className="form-container2" onSubmit={handleSignIn}>
        <div className="logo">
          <center><a href="https://theplannereducation.com/" target="_blank" rel="noopener noreferrer">
            <img src='/images/Theplanner_drk.png' alt="Logo" width="250px" />
          </a></center>
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </div>
        {emailError && <p className="error-message">{emailError}</p>}
        {passwordError && <p className="error-message">{passwordError}</p>}
        <div className="button-container">
          <button type="submit">Sign In</button>
        </div>
      </form>
    </div>
  );
}

SignIn.propTypes = {
  setUserData: PropTypes.func.isRequired,
};

export default SignIn;
