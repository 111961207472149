import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react';
import axios from 'axios';
import Select from 'react-select'; 
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import './Sales.css';
import CurrentInventory from '../Inventory/CurrentInventory';
import InventoryPage from '../Inventory/InventoryPage';
import InventoryReport from '../Inventory/InventoryReport';

const SalesReport = lazy(() => import('./SalesReport')); // Lazy load SalesReport component

const Sales = () => {
  const [activeTab, setActiveTab] = useState('sales'); // State to manage active tab
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState('');
  const [studentDetails, setStudentDetails] = useState({});
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [vat, setVat] = useState(0);
  const [vatChecked, setVatChecked] = useState(false); 
  const [totalAmount, setTotalAmount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [error, setError] = useState(null); 
  const [categories] = useState(['All', 'TEST', 'IELTS Book', 'TOEFL Book', 'GED Book', 'IGCSE Book', 'Other Book', 'Equipment', 'Others']);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const API_URL = process.env.REACT_APP_API_URL;

  const formatPrice = (price) => {
    const numPrice = Number(price);
    return isNaN(numPrice) ? '0.00' : numPrice.toFixed(2);
  };

  const fetchStudents = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/students`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const studentsData = response.data.students || [];
      setStudents(studentsData);
    } catch (error) {
      console.error('Error fetching students:', error);
      setError('Failed to fetch students.');
    }
  }, [API_URL]);

   // Fetch inventory items with authorization token
   const fetchItems = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/inventory`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const activeItems = response.data.filter((item) => item.status === 'active');
      setItems(activeItems);
    } catch (error) {
      console.error('Error fetching inventory items:', error);
      setError('Failed to fetch inventory items.');
    }
  }, [API_URL]);
  
  const calculateTotals = useCallback(() => {
    const total = selectedItems.reduce((acc, item) => acc + (item.price_per_unit * item.quantity), 0);
    const vatAmount = vatChecked ? (total - discount) * 0.07 : 0;
    setVat(vatAmount);
    setTotalAmount(total);
    setGrandTotal(total - discount + vatAmount);
  }, [selectedItems, discount, vatChecked]);

  useEffect(() => {
    fetchStudents();
    fetchItems();
  }, [fetchStudents, fetchItems]);

  useEffect(() => {
    calculateTotals();
  }, [selectedItems, discount, vatChecked, calculateTotals]);

  const handleStudentSelect = (selectedOption) => {
    setSelectedStudent(selectedOption ? selectedOption.value : '');
    const selectedStudentDetails = students.find(student => student.userid === selectedOption.value);
    setStudentDetails(selectedStudentDetails || {});
  };

  const handleVatCheckboxChange = (e) => {
    setVatChecked(e.target.checked);
  };

  const handleAddItem = (item) => {
    const existingItem = selectedItems.find(
      (selectedItem) => selectedItem.inventory_id === item.inventory_id
    );

    if (existingItem) {
      setSelectedItems(
        selectedItems.map((selectedItem) =>
          selectedItem.inventory_id === item.inventory_id
            ? { ...selectedItem, quantity: selectedItem.quantity + 1 }
            : selectedItem
        )
      );
    } else {
      setSelectedItems([...selectedItems, { ...item, quantity: 1 }]);
    }
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const filteredItems = selectedCategory === 'All'
    ? items
    : items.filter(item => item.category === selectedCategory);

  const handleRemoveItem = (inventory_id) => {
    setSelectedItems(
      selectedItems.filter((item) => item.inventory_id !== inventory_id)
    );
  };

  const handleConfirmPurchase = async () => {
    const saleData = {
      student_id: selectedStudent,
      items: selectedItems,
      discount: discount,
      vat: vat,
      payment_method: paymentMethod,
    };

    try {
      const token = localStorage.getItem('token');
      await axios.post(`${API_URL}/sales/log-sale`, saleData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      confirmAlert({
        title: 'Success',
        message: 'Sale logged successfully!',
        buttons: [
          {
            label: 'OK',
            onClick: () => {
              setSelectedStudent(null);
              setSelectedItems([]);
              setVat(0);
              setDiscount(0);
              setPaymentMethod('');
            }
          }
        ]
      });
      
    } catch (error) {
      console.error('Error logging sale:', error);
      alert('Failed to log sale. Please try again.');
    }
  };

  // Content for the Sales tab
  const renderSalesTab = () => (
    <div className="pos-layout">
      <div className="categories-section">
        <h2>Categories</h2>
        {categories.map((category) => (
          <button
            key={category}
            className={`category-button ${selectedCategory === category ? 'active' : ''}`}
            onClick={() => handleCategorySelect(category)}
          >
            {category}
          </button>
        ))}
      </div>

      <div className="menu-section">
        <div className="items-grid">
          {filteredItems.map((item) => (
            <div key={item.inventory_id} className="item-card">
              <h3>{item.item_name}</h3>
              <p>{formatPrice(item.price_per_unit)} THB</p>
              <button onClick={() => handleAddItem(item)}>Add</button>
            </div>
          ))}
        </div>
      </div>

      <div className="cart-section">
        <div className="student-select">
          <Select
            options={students.map(student => ({ value: student.userid, label: `${student.nickname}  ${student.firstname} ${student.lastname}` }))}
            onChange={handleStudentSelect}
            placeholder="Select or Search Student"
          />
          {selectedStudent && (
            <div className="student-details">
              <p>{studentDetails.nickname} {studentDetails.firstname} {studentDetails.lastname}</p>
            </div>
          )}
        </div>

        <div className="cart-items">
          {selectedItems.map((item) => (
            <div key={item.inventory_id} className="cart-item">
              <div className="item-details">
                <h4>{item.item_name}</h4>
                <p>{formatPrice(item.price_per_unit)} x {item.quantity}</p>
              </div>
              <button onClick={() => handleRemoveItem(item.inventory_id)}>Remove</button>
            </div>
          ))}
        </div>

        <div className="cart-summary">
          <div className="subtotal">
            <span>Subtotal</span>
            <span>{formatPrice(totalAmount)} THB</span>
          </div>
          <div className="vat">
            <label>
              <input
                type="checkbox"
                checked={vatChecked}
                onChange={handleVatCheckboxChange}
              />
              VAT (7%)
            </label>
          </div>
          <div className="total">
            <span>Total</span>
            <span>{formatPrice(grandTotal)} THB</span>
          </div>

          <div className="payment">
            <select
              onChange={(e) => setPaymentMethod(e.target.value)}
              value={paymentMethod}
            >
              <option value="">Select Payment Method</option>
              <option value="cash">Cash</option>
              <option value="QR">QR Code</option>
              <option value="credit_card">Credit Card</option>
              <option value="debit_card">Debit Card</option>
            </select>
          </div>

          <button className="pay-button" onClick={handleConfirmPurchase}>
            Pay {formatPrice(grandTotal)} THB
          </button>
        </div>
      </div>
    </div>
  );

  const renderSalesReportTab = () => (
    <Suspense fallback={<div>Loading...</div>}>
      <SalesReport />
    </Suspense>
  );

  return (
    <div className="pos-system">
      <Sidebar />
      <Header />
      {error && <p className="error">{error}</p>}
      
      <div className="tabs-container">
        <div
          className={`tutor-tab-button ${activeTab === 'sales' ? 'active' : ''}`}
          onClick={() => setActiveTab('sales')}
        >
          Sales
        </div>
        <div
          className={`tutor-tab-button ${activeTab === 'salesReport' ? 'active' : ''}`}
          onClick={() => setActiveTab('salesReport')}
        >
          Sales Report
        </div>

        <div
          className={`tutor-tab-button ${activeTab === 'currentInventory' ? 'active' : ''}`}
          onClick={() => setActiveTab('currentInventory')}
        >
          Current Inventory
        </div>
        <div
          className={`tutor-tab-button ${activeTab === 'add' ? 'active' : ''}`}
          onClick={() => setActiveTab('add')}
        >
          Add Inventory
        </div>

        <div
          className={`tutor-tab-button ${activeTab === 'report' ? 'active' : ''}`}
          onClick={() => setActiveTab('report')}
        >
          Inventory Report
        </div>
      </div>

      {/* Render the correct content based on activeTab */}
      {activeTab === 'sales' && renderSalesTab()}
      {activeTab === 'salesReport' && renderSalesReportTab()}
      {activeTab === 'currentInventory' && <CurrentInventory />} 
      {activeTab === 'add' && <InventoryPage />} 
      {activeTab === 'report' && <InventoryReport />}
    </div>
  );
};

export default withRoleAccess(Sales, ['superadmin', 'admin']);
