import React, { useState, useEffect } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import '../Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const UserControlPage = () => {
    const { t, i18n } = useTranslation();
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [roleFilter, setRoleFilter] = useState(''); // New state for role filter
    const [activeCounts, setActiveCounts] = useState({
        superadmin: { total: 0, active: 0 },
        admin: { total: 0, active: 0 },
        teacher: { total: 0, active: 0 },
        student: { total: 0, active: 0 },
        total: { total: 0, active: 0 }
    });
    const API_URL = process.env.REACT_APP_API_URL;
    const MAX_ACTIVE_USERS = { //[Full Access] Superadmin (5 user), Admin (15 users), Tutor (50 active users), Student (200 active users)**
        superadmin: 8,
        admin: 13,
        teacher: 51,
        student: 200,
    };
    const ITEMS_PER_PAGE = 150;
    const [currentPage, setCurrentPage] = useState(1);

    const handleShowAlert = (message) => {
        confirmAlert({
            title: t('alert'),
            message: message,
            buttons: [{ label: 'OK', onClick: () => {} }],
        });
    };

    const formatDateTime = (dateTimeString) => {
        if (!dateTimeString) return t('neversignin');
        const date = new Date(dateTimeString);
        if (isNaN(date.getTime())) return t('neversignin');
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat(i18n.language || 'en-UK', options).format(date).toUpperCase();
        const today = new Date();
        const diffTime = Math.abs(today - date);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        return `${formattedDate} (${diffDays} ${t('daysAgo')})`;
    };

    const fetchUsers = async () => {
        try {
            const token = localStorage.getItem('token');
            const [usersResponse, lastSigninResponse] = await Promise.all([
                axios.get(`${API_URL}/users`, { headers: { Authorization: `Bearer ${token}` } }),
                axios.get(`${API_URL}/users/last-signin`, { headers: { Authorization: `Bearer ${token}` } })
            ]);
            const lastSigninMap = lastSigninResponse.data.reduce((acc, curr) => {
                acc[curr.user_id] = curr.last_signin;
                return acc;
            }, {});
            const usersWithLastSignin = usersResponse.data.map(user => ({
                ...user,
                lastSignin: lastSigninMap[user.userid] || 'Never'
            }));
            const sortedUsers = usersWithLastSignin.sort((a, b) => a.userid - b.userid);
            setUsers(sortedUsers);
            setFilteredUsers(sortedUsers);
            calculateCounts(sortedUsers);
        } catch (error) {
            console.error('Error fetching users:', error);
            handleTokenError(error);
        }
    };

    const calculateCounts = (users) => {
        const counts = {
            superadmin: { total: 0, active: 0 },
            admin: { total: 0, active: 0 },
            teacher: { total: 0, active: 0 },
            student: { total: 0, active: 0 },
            total: { total: 0, active: 0 }
        };
        users.forEach(user => {
            if (counts.hasOwnProperty(user.role)) {
                counts[user.role].total += 1;
                if (user.status === 'active') counts[user.role].active += 1;
            }
            counts.total.total += 1;
            if (user.status === 'active') counts.total.active += 1;
        });
        setActiveCounts(counts);
    };

    useEffect(() => {
        fetchUsers();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const results = users.filter(user => {
            const matchesSearchTerm = `${user.firstname} ${user.lastname}`.toLowerCase().includes(searchTerm.toLowerCase())
                || user.email.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesRole = roleFilter ? user.role === roleFilter : true;
            return matchesSearchTerm && matchesRole;
        });
        setFilteredUsers(results);
        setCurrentPage(1);
    }, [searchTerm, roleFilter, users]);

    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentUsers = filteredUsers.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredUsers.length / ITEMS_PER_PAGE);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const goToPreviousPage = () => setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    const goToNextPage = () => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));

    const updateUserStatus = async (userId, status, role) => {
        if (status === 'active' && activeCounts[role].active >= MAX_ACTIVE_USERS[role]) {
            handleShowAlert(t('maxLimitReached', { role: t(role), limit: MAX_ACTIVE_USERS[role] }));
            return;
        }
        confirmAlert({
            title: t('confirmStatusChange'),
            buttons: [
                {
                    label: t('yes'),
                    onClick: async () => {
                        try {
                            const token = localStorage.getItem('token');
                            await axios.put(`${API_URL}/users/${userId}/status`, { status }, {
                                headers: { Authorization: `Bearer ${token}` },
                            });
                            fetchUsers();
                        } catch (error) {
                            console.error('Error updating user status:', error);
                            handleTokenError(error);
                        }
                    }
                },
                { label: t('no') }
            ]
        });
    };

    const resetPassword = async (userId) => {
        confirmAlert({
            title: t('confirmResetPassword'),
            buttons: [
                {
                    label: t('yes'),
                    onClick: async () => {
                        try {
                            const token = localStorage.getItem('token');
                            const response = await axios.put(`${API_URL}/users/${userId}/reset-password`, {}, {
                                headers: { Authorization: `Bearer ${token}` },
                            });
                            confirmAlert({
                                title: t('temporaryPasswordTitle'),
                                message: t('temporaryPassword', { userId, tempPassword: response.data.tempPassword }),
                                buttons: [{ label: 'OK', onClick: () => {} }]
                            });
                        } catch (error) {
                            console.error('Error resetting password:', error);
                            handleTokenError(error);
                        }
                    }
                },
                { label: t('no') }
            ]
        });
    };

    const handleTokenError = (error) => {
        if (isTokenExpiredError(error)) {
            refreshToken().then(() => fetchUsers()).catch(err => console.error('Token refresh failed:', err));
        }
    };

    const isTokenExpiredError = (error) => error.response && error.response.status === 401;
    const refreshToken = async () => {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await axios.post(`${API_URL}/refresh-token`, { refreshToken });
        localStorage.setItem('token', response.data.accessToken);
    };

    const getRoleClassName = (role) => {
        switch(role) {
            case 'superadmin': return 'role-superadmin';
            case 'admin': return 'role-admin';
            case 'teacher': return 'role-teacher';
            case 'student': return 'role-student';
            default: return '';
        }
    };

    return (
        <div>
            <Sidebar />
            <Header />
            <div className="dashboard-content">
                <h1>{t('userControlPage')}</h1>
            </div>
            <div className="main-content-full">
                <div className="warning">
                    <p><FontAwesomeIcon icon={faExclamationCircle} /> <strong>{t('warning')}:</strong> {t('warningMessage')}</p>
                </div>
                <div className="role-counts">
                    {Object.keys(MAX_ACTIVE_USERS).map(role => (
                        <div className="role-count-box" key={role}>
                            <strong>{t(role)}:</strong> <br/>
                            {t('maxUser')}: {MAX_ACTIVE_USERS[role]} <br/>
                            {t('activeUser')}: {activeCounts[role].active} <br/>
                            {t('inactiveUser')}: {activeCounts[role].total - activeCounts[role].active}
                        </div>
                    ))}
                </div>

                <div className="search-container">
                    <input
                        type="text"
                        placeholder={t('searchByName')}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        className="search-input"
                    />
                    <select
                        value={roleFilter}
                        onChange={e => setRoleFilter(e.target.value)}
                    >
                        <option value="">{t('ALL ROLES')}</option>
                        <option value="superadmin">{t('superadmin')}</option>
                        <option value="admin">{t('admin')}</option>
                        <option value="teacher">{t('teacher')}</option>
                        <option value="student">{t('student')}</option>
                    </select>
                </div>

                <div className="pagination-container">
                    <button onClick={goToPreviousPage} disabled={currentPage === 1} className="page-button">
                        {t('previous')}
                    </button>
                    {Array.from({ length: totalPages }, (_, i) => (
                        <button
                            key={i + 1}
                            onClick={() => paginate(i + 1)}
                            className={`page-button ${i + 1 === currentPage ? 'active' : ''}`}
                        >
                            {i + 1}
                        </button>
                    ))}
                    <button onClick={goToNextPage} disabled={currentPage === totalPages} className="page-button">
                        {t('next')}
                    </button>
                </div>

                <div className="task-list-container">
                    <table className="task-list-table">
                        <thead>
                            <tr>
                                <th>{t('userId')}</th>
                                <th>{t('username')}</th>
                                <th>{t('nickname')}</th>
                                <th>{t('email')}</th>
                                <th>{t('userRole')}</th>
                                <th>{t('lastSignin')}</th>
                                <th>{t('status')}</th>
                                <th>{t('resetPassword')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentUsers.map(user => (
                                <tr key={user.userid}>
                                    <td>{user.userid}</td>
                                    <td>{user.firstname} {user.lastname}</td>
                                    <td>{user.nickname}</td>
                                    <td>{user.email}</td>
                                    <td>
                                        <span className={getRoleClassName(user.role)}>{user.role}</span>
                                    </td>
                                    <td>{formatDateTime(user.lastSignin)}</td>
                                    <td>
                                        <select
                                            value={user.status || ''}
                                            onChange={e => updateUserStatus(user.userid, e.target.value, user.role)}
                                            className={user.status === 'active' ? 'select-active' : 'select-inactive'}
                                        >
                                            <option value="active">{t('active')}</option>
                                            <option value="inactive">{t('inactive')}</option>
                                        </select>
                                    </td>
                                    <td>
                                        {user.userid !== 1 && (
                                            <button className="reset-button" onClick={() => resetPassword(user.userid)}>
                                                <FontAwesomeIcon icon={faExclamationCircle} /> {t('resetPassword')}
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default withRoleAccess(UserControlPage, ['superadmin', 'admin']);
