import axios from 'axios';
import moment from 'moment-timezone';

const API_URL = process.env.REACT_APP_API_URL;

export const getTasks = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/events`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    const tasks = response.data.map(task => ({
      ...task,
      start: moment.utc(task.start).local().format(),
      end: moment.utc(task.end).local().format(),
    }));
    return tasks;
  } catch (error) {
    throw error;
  }
};

export const createTask = async (taskDetails) => {
  try {
    const token = localStorage.getItem('token');
    const taskDetailsUTC = {
      ...taskDetails,
      start: moment(taskDetails.start).utc().format(),
      end: moment(taskDetails.end).utc().format(),
    };
    const response = await axios.post(`${API_URL}/events`, taskDetailsUTC, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTask = async (taskId, updatedTaskDetails) => {
  try {
    const token = localStorage.getItem('token');

    // Convert start and end to UTC
    const { start, end, ...restDetails } = updatedTaskDetails;
    const startUTC = moment.tz(start, 'Asia/Bangkok').utc().format();
    const endUTC = moment.tz(end, 'Asia/Bangkok').utc().format();

    // Include the converted times in the update details
    const taskUpdateData = {
      ...restDetails,
      start: startUTC,
      end: endUTC,
    };

    const response = await axios.put(`${API_URL}/events/${taskId}`, taskUpdateData, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const deleteTask = async (taskId, params) => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No token found');
  }

  // Include deleteGroup flag as a query parameter in the DELETE request
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/events/${taskId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: params, // Add the params (deleteGroup flag) here
  });

  return response.data;
};
