import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to get the student ID from URL
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendar, faFileText } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import CustomPieChartStudent from '../../components/CustomPieChartStudent'; 
import './MyPerformance.css';

const API_URL = process.env.REACT_APP_API_URL;

const StudentPerformance = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams(); // Use useParams to get the student ID
  const [registrations, setRegistrations] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [activeTab, setActiveTab] = useState(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };

  const calculateEndTime = (startTime, duration) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [durationHours, durationMinutes] = duration.split(':').map(Number);

    let endHours = startHours + durationHours;
    let endMinutes = startMinutes + durationMinutes;

    if (endMinutes >= 60) {
      endHours += Math.floor(endMinutes / 60);
      endMinutes %= 60;
    }

    return `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const fetchRegistrations = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/registrations/student/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setRegistrations(response.data.registrations);
        setActiveTab(response.data.registrations[0]?.groupid || null);
      } catch (error) {
        console.error('Error fetching registrations:', error);
      }
    };

    const fetchAttendance = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/attendance/student/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setAttendance(response.data);
      } catch (error) {
        console.error('Error fetching attendance logs:', error);
      }
    };

    fetchRegistrations();
    fetchAttendance();
  }, [id]); // Now using id from useParams

  const getAttendanceForClass = (classId) => {
    return attendance.find((log) => log.classid === classId);
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
  };

  const getTextForStatus = (status) => {
    switch (status) {
      case 'Present':
        return t('finished');
      case 'Late':
        return t('late');
      case 'Absent':
        return t('absent');
      case 'Rescheduled':
        return t('rescheduled');
      default:
        return t('scheduled');
    }
  };

  const getClassCardStyle = (status) => {
    switch (status) {
      case 'Present':
        return 'class-box present';
      case 'Late':
        return 'class-box late';
      case 'Absent':
        return 'class-box absent';
      case 'Rescheduled':
        return 'class-box rescheduled';
      default:
        return 'class-box default';
    }
  };

  const handleTabClick = (groupid) => {
    setActiveTab(groupid);
  };

  return (
    <div className="my-performance">
      <Sidebar />
      <Header />
      <div className="performance-content">
        <div className="tabs">
          {registrations.map((registration) => (
            <button
              key={registration.groupid}
              className={`tab-button ${activeTab === registration.groupid ? 'active' : ''}`}
              onClick={() => handleTabClick(registration.groupid)}
            >
              {registration.class_code}
            </button>
          ))}
        </div>

        {registrations.map((registration) =>
          activeTab === registration.groupid ? (
            <div key={registration.groupid} className="group-section">
              <div className="progress-container">
                <CustomPieChartStudent
                  data={registration.schedule.map(cls => ({
                    classid: cls.classid,
                    status: getAttendanceForClass(cls.classid)?.status || 'No Attendance'
                  }))}
                />
              </div>
              <div className="classes-container">
                {registration.schedule.sort((a, b) => new Date(a.date) - new Date(b.date)).map((cls, classIndex) => {
                  const attendanceLog = getAttendanceForClass(cls.classid);
                  const status = attendanceLog ? attendanceLog.status : 'No Attendance';
                  const cardClassName = `class-box ${status.toLowerCase()}`;

                  return (
                    <div key={classIndex} className={cardClassName}>
                      <div className={getClassCardStyle(status)}>
                        <div className="class-box-header">
                          <h3>{cls.subject}</h3>
                          <div className="status-icon">
                            <span>{getTextForStatus(status)}</span>
                          </div>
                        </div>
                        <div className="class-box-body">
                          <div className="date-time-row">
                            <div><strong><FontAwesomeIcon icon={faCalendar} /> {t('date')}</strong> {formatDate(cls.date)}</div>
                            <div><strong><FontAwesomeIcon icon={faClock} /> {t('time')}</strong> {formatTime(cls.time)} - {calculateEndTime(cls.time, cls.hours)}</div>
                          </div>
                          {attendanceLog && attendanceLog.comment && (
                            <div><strong><FontAwesomeIcon icon={faFileText} /> {t('comments')}</strong> {attendanceLog.comment}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export default withRoleAccess(StudentPerformance, ['admin', 'superadmin']); 
