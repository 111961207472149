import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './InventoryPage.css'; 
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { CSVLink } from 'react-csv'; // Import CSVLink for CSV export
import { useTranslation } from 'react-i18next';

const InventoryReport = () => {
    const { t } = useTranslation();
    const [reportData, setReportData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [filters, setFilters] = useState({ fromDate: '', toDate: '' }); // State for date filters
    const itemsPerPage = 50;

    const API_URL = process.env.REACT_APP_API_URL;

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        return isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        });
    };

    useEffect(() => {
        const fetchReportData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${API_URL}/inventory/report`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const sortedData = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
                setReportData(sortedData);
                setFilteredData(sortedData); // Initialize filtered data with full data set
                setLoading(false);
            } catch (err) {
                console.error('Error fetching inventory report:', err);
                setError('Failed to fetch inventory report.');
                setLoading(false);
            }
        };

        fetchReportData();
    }, [API_URL]);

    // Pagination logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Handle filter changes
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    // Apply filters to the report data
    const applyFilters = () => {
        let filtered = reportData;

        if (filters.fromDate) {
            filtered = filtered.filter(item => new Date(item.timestamp) >= new Date(filters.fromDate));
        }

        if (filters.toDate) {
            filtered = filtered.filter(item => new Date(item.timestamp) <= new Date(filters.toDate));
        }

        setFilteredData(filtered);
        setCurrentPage(1); // Reset to first page when filters are applied
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    // Prepare CSV data based on filtered data
    const csvData = filteredData.map(item => ({
        product_id: item.product_id,
        item_name: item.item_name,
        item_description: item.item_description,
        category: item.category,
        price_per_unit: item.price_per_unit,
        transaction_type: item.transaction_type,
        quantity_change: item.quantity_change,
        change_reason: item.change_reason,
        timestamp: formatDateTime(item.timestamp)
    }));

    return (
        <div className="main-content">
            <Sidebar />
            <Header />
            <h1>{t('Inventory Report')}</h1>

            {/* Date Filter and CSV Download */}
            <div className="filter-container">
                <label>
                    From Date:
                    <input
                        type="date"
                        name="fromDate"
                        value={filters.fromDate}
                        onChange={handleFilterChange}
                    />
                </label>
                <label>
                    To Date:
                    <input
                        type="date"
                        name="toDate"
                        value={filters.toDate}
                        onChange={handleFilterChange}
                    />
                </label>
                <button onClick={applyFilters} className="filter-button">
                    Apply Filters
                </button>
                <CSVLink
                    data={csvData}
                    filename={"inventory_report.csv"}
                    className="btn btn-success export-button"
                    target="_blank"
                >
                    Export CSV
                </CSVLink>
            </div>

            {/* Pagination Controls */}
            <div className="pagination-container">
                <button className="page-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                    <button 
                        key={index + 1}
                        onClick={() => handlePageChange(index + 1)}
                        className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
                    >
                        {index + 1}
                    </button>
                ))}
                <button className="page-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>

            {/* Inventory Table */}
            <table className="inventory-table">
                <thead>
                    <tr>
                        <th>{t('productid')}</th>
                        <th>{t('itemname')}</th>
                        <th>{t('description')}</th>
                        <th>{t('category')}</th>
                        <th>{t('priceperunit')}</th>
                        <th>{t('type')}</th>
                        <th>{t('unit')}</th>
                        <th>{t('changereason')}</th>
                        <th>{t('document')}</th>
                        <th>{t('date')}</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item, index) => (
                        <tr key={index}>
                            <td>{item.product_id}</td>
                            <td>{item.item_name}</td>
                            <td>{item.item_description}</td>
                            <td>{item.category}</td>
                            <td>{item.price_per_unit}</td> 
                            <td>{item.transaction_type}</td>   
                            <td>{item.quantity_change}</td>
                            <td>{item.change_reason}</td>
                            <td>{item.document_no || item.invoiceno}</td>
                            <td>{formatDateTime(item.timestamp)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default withRoleAccess(InventoryReport, ['superadmin', 'admin']);
