import React, { useEffect, useState } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { 
  Layout,
  Card,
  Typography,
  Avatar,
  Space,
  Spin,
  Alert,
  Tag,
  Divider,
  Row,
  Col
} from 'antd';
import {
  BookOutlined,
  TrophyOutlined,
  ScheduleOutlined
} from '@ant-design/icons';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { refreshToken, isTokenExpiredError } from '../../Utils/authUtils';

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;
const { Meta } = Card;

function Teacher() {
  const { t } = useTranslation();
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/teachers`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setTeachers(response.data);
        setLoading(false);
      } catch (error) {
        if (isTokenExpiredError(error)) {
          refreshToken()
            .then(() => {
              fetchTeachers();
            })
            .catch(err => {
              console.error('Token refresh failed:', err);
              setError(t('sessionExpired'));
            });
        } else {
          console.error('Error fetching teachers:', error);
          setError(t('errorFetchingTeachers'));
        }
        setLoading(false);
      }
    };

    fetchTeachers();
  }, [API_URL, t]);

  const EXCLUDED_IDS = [16, 51, 52, 54];

  const renderTeacherCard = (teacher) => {
    const specializations = teacher.specialize_subjects?.split(',') || [];
  
    return (
      <Card hoverable style={{ height: '100%' }}>
        <div style={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          marginBottom: 20
        }}>
          <Avatar
            size={120}
            src={teacher.photourl}
            alt={`${teacher.firstname} ${teacher.lastname}`}
            style={{
              border: '4px solid #f0f0f0',
              marginBottom: 16,
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
            }}
          />
          <Space direction="vertical" size={2} style={{ width: '100%' }}>
            <Title level={4} style={{ margin: 0 }}>
              {teacher.firstname} {teacher.lastname}
            </Title>
            {teacher.nickname && (
              <Text type="secondary">({teacher.nickname})</Text>
            )}
          </Space>
        </div>
  
        <Divider style={{ margin: '12px 0' }} />
  
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
          {specializations.length > 0 && (
              <Space direction="vertical" size={8} style={{ width: '100%' }}>
              <Text strong>
                <BookOutlined style={{ marginRight: 8 }} />
                {t('subjects')}
              </Text>
              <Text style={{ 
                wordWrap: 'break-word', 
                whiteSpace: 'normal',
                lineHeight: '1.6'
              }}>
                {specializations.join(', ')}
              </Text>
            </Space>
          )}
  
          {(teacher.bachelor_degree || teacher.master_degree || teacher.doctoral_degree) && (
            <Space direction="vertical" size={8} style={{ width: '100%' }}>
              <Text strong>
                <TrophyOutlined style={{ marginRight: 8 }} />
                {t('Education')}
              </Text>
              {renderEducation(teacher.doctoral_degree, teacher.doctoral_school)}
              {renderEducation(teacher.master_degree, teacher.master_school)}
              {renderEducation(teacher.bachelor_degree, teacher.bachelor_school)}
            </Space>
          )}
        </Space>
      </Card>
    );
  };
  
  // Update renderEducation for better layout
  const renderEducation = (degree, school) => {
    if (!degree) return null;
    return (
      <div style={{ textAlign: 'center' }}>
        <Text>{degree}</Text>
        <br />
        <Text type="secondary" style={{ fontSize: '0.9em' }}>{school}</Text>
      </div>
    );
  };
  
  // Main component remains the same, just update the Content padding
  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content style={{ 
          padding: { xs: '12px', sm: '24px' }, 
          minHeight: '280px', 
          background: '#f5f5f5',
          marginTop: '70px', 
          marginBottom: '70px'
        }}>
          <div style={{ maxWidth: 1200, margin: '0 auto' }}>
            <Row gutter={[24, 24]}>
              {teachers
                .filter((teacher) => 
                  teacher.status === 'active' && 
                  !EXCLUDED_IDS.includes(teacher.userid) &&
                  !EXCLUDED_IDS.includes(teacher.teacherid)
                )
                .sort((a, b) => a.userid - b.userid)
                .map((teacher) => (
                  <Col 
                    key={teacher.userid}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={8}
                    xl={8}
                  >
                    {renderTeacherCard(teacher)}
                  </Col>
                ))}
            </Row>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
  
}

export default withRoleAccess(Teacher, ['admin', 'superadmin', 'teacher', 'student']);