import React, { useState } from 'react';
import '../Dashboard.css';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import EditDeleteTeacher from './EditDeleteTeacher';
import EditDeleteStudent from './EditDeleteStudent';
import EditDeleteSubject from './EditDeleteSubject';
import FilterTaskTable from '../Class/FilterTaskTable';
import ClassChange from '../Class/ClassChange';
import Classes from '../Class/Classes';
import EditDeleteClassroom from './EditDeleteClassroom';
import AnnouncementAdmin from '../Announcement/AnnouncementAdmin';
import MatchTeacherSubject from '../SubjectMatch/MatchTeacherSubject';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import CreateTeacherForm from '../CreateUserform/CreateTeacherForm';
import CreateStudentForm from '../CreateUserform/CreateStudentForm';
import TutorClassLog from '../Teacher/TutorClassLog';
import CreateCourse from '../Class/CreateCourse';

function AdminPanel() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('teachers');
  const [showCreateTeacherForm, setShowCreateTeacherForm] = useState(false);
  const [showCreateStudentForm, setShowCreateStudentForm] = useState(false);

  const toggleCreateTeacherForm = () => {
    setShowCreateTeacherForm(!showCreateTeacherForm);
  };

  const toggleCreateStudentForm = () => {
    setShowCreateStudentForm(!showCreateStudentForm);
  };

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="main">
        <div className="tabs-container">
          <button className={`tutor-tab-button ${activeTab === 'teachers' ? 'active' : ''}`} onClick={() => setActiveTab('teachers')}>
            <span>{t('tutors')}</span>
          </button>
          <button className={`tutor-tab-button ${activeTab === 'students' ? 'active' : ''}`} onClick={() => setActiveTab('students')}>
            <span>{t('students')}</span>
          </button>
          <button className={`tutor-tab-button ${activeTab === 'subjects' ? 'active' : ''}`} onClick={() => setActiveTab('subjects')}>
            <span>{t('subjects')}</span>
          </button>
          <button className={`tutor-tab-button ${activeTab === 'classrooms' ? 'active' : ''}`} onClick={() => setActiveTab('classrooms')}>
            <span>{t('classrooms')}</span>
          </button>
          <button className={`tutor-tab-button ${activeTab === 'class' ? 'active' : ''}`} onClick={() => setActiveTab('class')}>
            <span>{t('createCourse1')}</span>
          </button>
          <button className={`tutor-tab-button ${activeTab === 'createcourse' ? 'active' : ''}`} onClick={() => setActiveTab('createcourse')}>
            <span>{t('createCourse2')}</span>
          </button>
          <button className={`tutor-tab-button ${activeTab === 'courses' ? 'active' : ''}`} onClick={() => setActiveTab('courses')}>
            <span>{t('manageCourses')}</span>
          </button>
          <button className={`tutor-tab-button ${activeTab === 'classchange' ? 'active' : ''}`} onClick={() => setActiveTab('classchange')}>
            <span>{t('classchange')}</span>
          </button>
          <button className={`tutor-tab-button ${activeTab === 'annoucements' ? 'active' : ''}`} onClick={() => setActiveTab('annoucements')}>
            <span>{t('announcements')}</span>
          </button>
          <button className={`tutor-tab-button ${activeTab === 'matchteachersubject' ? 'active' : ''}`} onClick={() => setActiveTab('matchteachersubject')}>
            <span>{t('matchteachersubject')}</span>
          </button>
          <button className={`tutor-tab-button ${activeTab === 'teacherhourlog' ? 'active' : ''}`} onClick={() => setActiveTab('teacherhourlog')}>
            <span>{t('teacherhourlog')}</span>
          </button>
        </div>
        <div className="main-content">
          <div className="warning">
            <p><FontAwesomeIcon icon={faExclamationCircle} /> <strong>{t('warning')}</strong>: {t('warningMessage')}</p>
          </div>
          <div>
            {activeTab === 'teachers' && !showCreateTeacherForm && (
              <EditDeleteTeacher onCreateNewTeacher={toggleCreateTeacherForm} />
            )}
            {activeTab === 'teachers' && showCreateTeacherForm && (
              <CreateTeacherForm onClose={toggleCreateTeacherForm} />
            )}
            {activeTab === 'students' && !showCreateStudentForm && (
              <EditDeleteStudent onCreateNewStudent={toggleCreateStudentForm} />
            )}
            {activeTab === 'students' && showCreateStudentForm && (
              <CreateStudentForm onClose={toggleCreateStudentForm} />
            )}
            {activeTab === 'class' && <Classes />}
            {activeTab === 'createcourse' && <CreateCourse />}
            {activeTab === 'courses' && <FilterTaskTable />}
            {activeTab === 'subjects' && <EditDeleteSubject />}
            {activeTab === 'classrooms' && <EditDeleteClassroom />}
            {activeTab === 'annoucements' && <AnnouncementAdmin />}
            {activeTab === 'classchange' && <ClassChange />}
            {activeTab === 'matchteachersubject' && <MatchTeacherSubject />}
            {activeTab === 'teacherhourlog' && <TutorClassLog />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRoleAccess(AdminPanel, ['admin', 'superadmin']);
