import React, { useState, useEffect } from "react";
import { jwtDecode } from 'jwt-decode';
import Sidebar from '../components/Sidebar/Sidebar';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import AdminDashboard from './AdminDashboard';
import TeacherDashboard from '../Pages/Teacher/TeacherDashboard';
import StudentDashboard from '../Pages/Student/StudentDashboard';
import './Dashboard.css';

function Dashboard() {
  const [role, setRole] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError('No token found');
          return;
        }

        const decodedToken = jwtDecode(token);
        setRole(decodedToken.role);
      } catch (error) {
        console.error('Error decoding token:', error);
        setError('Failed to decode token.');
      }
    };

    fetchUserData();
  }, []);

  if (error) return <p>{error}</p>;
  if (role === null) return <p>Loading...</p>;

  return (
    <div className="dashboard-container">
      <Sidebar />
      <Header />
      <div className="dashboard-content">
        {role === 'superadmin' && <AdminDashboard />}
        {role === 'admin' && <AdminDashboard />}
        {role === 'teacher' && <TeacherDashboard />}
        {role === 'student' && <StudentDashboard />}
      </div>
      <Footer />
    </div>
  );
}

export default Dashboard;
