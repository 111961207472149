import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import withRoleAccess from '../../hoc/withRoleAccess';
import axios from 'axios';
import getUserIdFromToken from '../../Utils/authUtils';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Layout, Table, Select, Button, Card, Tag, Pagination, Space, notification } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import './Teacher.css';
import { useTranslation } from 'react-i18next';

const { Content } = Layout;
const { Option } = Select;

const generateMonthYearOptions = () => {
  const options = [];
  const startYear = 2024;
  const startMonth = 7;
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  for (let year = startYear; year <= currentYear + 5; year++) {
    for (let month = 0; month < 12; month++) {
      if (year === startYear && month < startMonth) continue;
      const monthYear = new Date(year, month).toLocaleString('default', { month: 'short', year: 'numeric' }).toUpperCase();
      options.push({ value: `${year}-${month + 1}`, label: monthYear });
      if (year === currentYear && month >= currentMonth) continue;
    }
  }

  return options;
};

const getChangeReasonDisplay = (reason) => {
  switch (reason) {
    case 'admin_adjust':
      return { text: 'Admin', color: '#005181' };
    case 'cancelled_by_student':
      return { text: 'CL by S.', color: 'red' };
    case 'cancelled_by_teacher':
      return { text: 'CL by T.', color: 'orange' };
    case 'class_on_hold_by_student':
      return { text: 'On hold by S.', color: 'blue' };
    case 'class_on_hold_by_school':
      return { text: 'On hold by P.', color: 'green' };
    case 'others':
      return { text: 'Others', color: 'darkgrey' };
    default:
      return { text: reason, color: 'black' };
  }
};

const TeacherHours = () => {
  const userId = getUserIdFromToken();
  const { t } = useTranslation();
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [classChangeLogs, setClassChangeLogs] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`);
  const [payoutFilter, setPayoutFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const API_URL = process.env.REACT_APP_API_URL;
  const [shownToasts, setShownToasts] = useState(new Set());

  const formatDate = (dateString) => moment(dateString).format('DD MMM YYYY');
  const formatTime = (timeString) => moment(timeString, 'HH:mm').format('HH:mm');

  const formatScheduleHour = (scheduleHourString) => {
    const [hours, minutes] = scheduleHourString.split(' ').map((value) => parseInt(value));
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  };

  const calculateTimeRange = (scheduleTime, scheduleHour) => {
    const startTime = moment(scheduleTime, 'HH:mm');
    const [hours, minutes] = scheduleHour.split(' ').map(value => parseInt(value));
    const endTime = startTime.clone().add(hours, 'hours').add(minutes, 'minutes');
    return `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}`;
  };
  
  useEffect(() => {
    const fetchClassesWithStatus = async () => {
      const token = localStorage.getItem('token');
      try {
        const [classesRes, statusRes] = await Promise.all([
          axios.get(`${API_URL}/classes/teacher/${userId}`, { headers: { Authorization: `Bearer ${token}` } }),
          axios.get(`${API_URL}/classes/${userId}/classes-status`, { headers: { Authorization: `Bearer ${token}` } }),
        ]);

        const classesWithStatus = classesRes.data.map(cls => {
          const statusClass = statusRes.data.find(statusCls => statusCls.classid === cls.classid);
          return {
            ...cls,
            status: statusClass ? statusClass.status : 'pending',
            payout: statusClass ? statusClass.payout : 'pending',
          };
        });
        setClasses(classesWithStatus);
        setFilteredClasses(classesWithStatus);
      } catch (error) {
        console.error(t('errorFetchingClasses'), error);
      }
    };
    fetchClassesWithStatus();
  }, [userId, API_URL, t]);

  useEffect(() => {
    const fetchClassChangeLogs = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${API_URL}/class-change/class-change-logs`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setClassChangeLogs(response.data);
      } catch (error) {
        console.error('Error fetching class change logs:', error);
      }
    };
    fetchClassChangeLogs();
  }, [API_URL]);

  useEffect(() => {
    classChangeLogs.forEach(log => {
      if (log.changereason === 'cancelled_by_student' && !shownToasts.has(log.id)) {
        toast.error(`Class ${log.class_code} was cancelled by the student.`, {
          position: "top-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        setShownToasts(prev => new Set(prev).add(log.id));
      }
    });
  }, [classChangeLogs, shownToasts]);

  useEffect(() => {
    const applyFilters = () => {
      let filtered = classes.filter(cls => {
        const classDate = new Date(cls.date);
        const classMonthYear = `${classDate.getFullYear()}-${classDate.getMonth() + 1}`;
        const isMonthYearMatch = classMonthYear === selectedMonthYear;
        return isMonthYearMatch;
      });

      if (payoutFilter) {
        filtered = filtered.filter(cls => cls.payout === payoutFilter);
      }

      setFilteredClasses(filtered);
      setCurrentPage(1);
    };
    applyFilters();
  }, [selectedMonthYear, classes, payoutFilter]);

  const handleApprove = (classId, classDate) => {
    const today = moment().format('YYYY-MM-DD');
    if (classDate > today) {
      return notification.error({
        message: t('Cannot Approve'),
        description: t('Class cannot be confirmed before the actual date of teaching'),
      });
    }

    confirmAlert({
      title: t('Confirm Approval'),
      message: t('Are you sure you want to approve this class?'),
      buttons: [
        { label: t('Yes'), onClick: () => handleSubmitApproval(classId) },
        { label: t('No'), onClick: () => {} },
      ],
    });
  };

  const handleSubmitApproval = async (classId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${API_URL}/classes/${classId}/approve`, {}, { headers: { Authorization: `Bearer ${token}` } });
      notification.success({ message: t('Class approved successfully!') });
      setClasses(prevClasses => prevClasses.map(cls => cls.classid === classId ? { ...cls, status: 'approved' } : cls));
    } catch (error) {
      notification.error({ message: t('Failed to approve class. Please try again.') });
    }
  };

  const columns = [
    { title: t('classcode'), dataIndex: 'class_code', key: 'class_code', ellipsis: true },
    { title: t('classid'), dataIndex: 'classid', key: 'classid', ellipsis: true },
    { title: t('subjectName'), dataIndex: 'subject_name', key: 'subject_name', ellipsis: true },
    { title: t('date'), dataIndex: 'date', key: 'date', render: formatDate },
    {
      title: t('time'),
      key: 'time_range',
      render: (_, record) => calculateTimeRange(record.schedule_time, record.schedule_hour),
    },
    { title: t('hours'), dataIndex: 'schedule_hour', key: 'schedule_hour', render: formatScheduleHour },
    {
      title: t('approve'),
      key: 'approve',
      render: (_, record) => (
        record.status === 'approved' ? (
          <Tag icon={<CheckCircleOutlined />} color="success">{t('HOURSAPPROVED')}</Tag>
        ) : (
          <Button type="primary" size="small" onClick={() => handleApprove(record.classid, record.date)}>
            {t('confirmteach')}
          </Button>
        )
      ),
    },
    {
      title: t('payment'),
      key: 'payment',
      render: (_, record) => (
        <Tag color={record.payout === 'pending' ? 'orange' : 'green'}>
          {record.payout === 'pending' ? t('Pending') : t('Paid')}
        </Tag>
      ),
    },
    {
      title: t('classchange'),
      key: 'classchange',
      render: (_, record) => {
        const changeLog = classChangeLogs.find(log => log.classid === record.classid);
        const changeReason = changeLog ? changeLog.changereason : '';
        const { text, color } = getChangeReasonDisplay(changeReason);
        return <Tag color={color}>{text}</Tag>;
      },
    },
  ];

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content className="teacher-hours-container">
          <Card title={t('my_hours')}>
            <Space style={{ marginBottom: 16 }}>
              <Select
                value={selectedMonthYear}
                onChange={value => setSelectedMonthYear(value)}
                style={{ width: 150 }}
              >
                {generateMonthYearOptions().map(option => (
                  <Option key={option.value} value={option.value}>{option.label}</Option>
                ))}
              </Select>
              <Select
                value={payoutFilter}
                onChange={value => setPayoutFilter(value)}
                style={{ width: 150 }}
              >
                <Option value="">{t('All Payouts')}</Option>
                <Option value="pending">{t('Pending')}</Option>
                <Option value="paid">{t('Paid')}</Option>
              </Select>
            </Space>
            <Table
                columns={columns}
                dataSource={filteredClasses}
                rowKey="classid"
                pagination={{
                  current: currentPage,
                  pageSize: itemsPerPage,
                  total: filteredClasses.length,
                  onChange: setCurrentPage,
                  showSizeChanger: false,
                }}
                scroll={{ x: 'max-content' }} // Enable horizontal scrolling
                style={{ width: '100%' }}
              />
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRoleAccess(TeacherHours, ['teacher']);
