import React, { useState, useEffect } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faStar, faTrash, faUser, faUserGraduate, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import PasswordModal from './PasswordModal';
import EditStudentModal from './EditStudentModal';
import '../Dashboard.css';
import { refreshToken, isTokenExpiredError } from '../../Utils/authUtils';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

function EditDeleteStudent({ onCreateNewStudent }) {
    const { t } = useTranslation();
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [studentIdToDelete, setStudentIdToDelete] = useState(null);
    const [studentIdToEdit, setStudentIdToEdit] = useState(null);
    const [passwordError, setPasswordError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageGroup, setCurrentPageGroup] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [nameSearch, setNameSearch] = useState('');
    const [phoneSearch, setPhoneSearch] = useState('');
    const [schoolIdSearch, setSchoolIdSearch] = useState('');
    const rowsPerPage = 100; 
    const pagesPerGroup = 10;
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_URL;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat(t('locale'), options).format(date).toUpperCase();
    };

    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const token = localStorage.getItem('token');
                const queryParams = new URLSearchParams({
                    page: currentPage,
                    name: nameSearch,
                    phoneNumber: phoneSearch,
                    schoolId: schoolIdSearch
                });

                const response = await axios.get(`${API_URL}/students?${queryParams.toString()}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                setStudents(response.data.students);
                setTotalPages(response.data.totalPages);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching students:', error);
                if (isTokenExpiredError(error)) {
                    refreshToken().then(() => {
                        fetchStudents();
                    }).catch(err => {
                        console.error('Token refresh failed:', err);
                        setError(t('sessionExpired'));
                    });
                } else {
                    setError(t('fetchStudentsError'));
                    setLoading(false);
                }
            }
        };

        fetchStudents();
    }, [currentPage, nameSearch, phoneSearch, schoolIdSearch, API_URL, t]);

    const startPage = currentPageGroup * pagesPerGroup + 1;
    const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages);

    const goToPreviousPageGroup = () => {
        if (currentPageGroup > 0) {
            setCurrentPageGroup(currentPageGroup - 1);
            setCurrentPage((currentPageGroup - 1) * pagesPerGroup + 1);
        }
    };

    const goToNextPageGroup = () => {
        if (endPage < totalPages) {
            setCurrentPageGroup(currentPageGroup + 1);
            setCurrentPage((currentPageGroup + 1) * pagesPerGroup + 1);
        }
    };

    const goToPage = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const currentStudents = students.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
      );

    const handleEdit = (studentId) => {
        setStudentIdToEdit(studentId);
        setIsEditModalOpen(true);
    };

    const handleDelete = (studentId) => {
        setStudentIdToDelete(studentId);
        setPasswordError('');
        setIsPasswordModalOpen(true);
    };

    const confirmDelete = async (password) => {
        setIsPasswordModalOpen(false);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                setError(t('noTokenFound'));
                return;
            }
            await axios.delete(`${API_URL}/students/${studentIdToDelete}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: { password }
            });
            setStudents(prev => prev.filter(student => student.userid !== studentIdToDelete));

            confirmAlert({
                title: t('success'),
                message: t('studentDeleted'),
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {},
                    }
                ]
            });
        } catch (error) {
            console.error('Error deleting student:', error);
            if (error.response && error.response.status === 401) {
                setPasswordError(t('invalidPassword'));
                setIsPasswordModalOpen(true); 
            } else {
                setError(t('deleteStudentError'));
            }
        }
    };

    const saveStudent = async (updatedStudent) => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(`${API_URL}/students/${updatedStudent.userid}`, updatedStudent, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setStudents(prev => prev.map(student => student.userid === updatedStudent.userid ? updatedStudent : student)); // Update local state
            setIsEditModalOpen(false);

            confirmAlert({
                title: t('success'),
                message: t('studentUpdated'),
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => {},
                    }
                ]
            });
        } catch (error) {
            console.error('Error updating student:', error);
            setError(t('updateStudentError'));
        }
    };

    const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    const viewStudentDashboard = (studentId) => {
        navigate(`/student-dashboard-admin/${studentId}`);
    };

    const viewStudentPerformance = (studentId) => {
        navigate(`/studentperformance/${studentId}`);
    };

    if (loading) return <p>{t('loading')}</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="adminstudent-container">
            <div className="admincontainer">
                <button className="create-student-button" onClick={onCreateNewStudent}>
                    <FontAwesomeIcon icon={faUserGraduate} /> {t('createNewStudent')}
                </button>
                <div className="search-container">
                    <input
                        type="text"
                        value={nameSearch}
                        onChange={e => setNameSearch(e.target.value)}
                        placeholder={t('searchByName')}
                    />
                    <input
                        type="text"
                        value={phoneSearch}
                        onChange={e => setPhoneSearch(e.target.value)}
                        placeholder={t('searchByPhone')}
                    />
                    <input
                        type="text"
                        value={schoolIdSearch}
                        onChange={e => setSchoolIdSearch(e.target.value)}
                        placeholder={t('searchBySchoolID')}
                    />
                </div>
                <div className="pagination-container">
                    <button
                        onClick={goToPreviousPageGroup}
                        disabled={currentPageGroup === 0}
                        className="page-button"
                    >
                        {t('Prev 10 Pages')}
                    </button>

                    {Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index).map((page) => (
                        <button
                        key={page}
                        onClick={() => goToPage(page)}
                        className={`page-button ${currentPage === page ? 'active' : ''}`}
                        >
                        {page}
                        </button>
                    ))}

                    <button
                        onClick={goToNextPageGroup}
                        disabled={endPage >= totalPages}
                        className="page-button"
                    >
                        {t('Next 10 Pages')}
                    </button>
                    </div>

                <div className="task-list-container">
                    <table className="task-list-table">
                        <thead>
                            <tr>
                                <th>{t('')}</th>
                                <th>{t('')}</th>
                                <th>{t('id')}</th>
                                <th>{t('name')}</th>
                                <th>{t('nickname')}</th>
                                <th>{t('dob')}</th>
                                <th>{t('age')}</th>
                                <th>{t('email')}</th>
                                <th>{t('phone')}</th>
                                <th>{t('level')}</th>
                                <th>{t('school')}</th>
                                <th>{t('id')}</th>
                                <th>{t('actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {currentStudents.map((student) => (
                                <tr key={student.userid}>
                                    <td>
                                        <button onClick={() => viewStudentDashboard(student.userid)} className="dashboard-button">
                                            <FontAwesomeIcon icon={faUser} />
                                        </button>
                                    </td>
                                    <td>
                                        <button onClick={() => viewStudentPerformance(student.userid)} className="performance-button">
                                            <FontAwesomeIcon icon={faStar} />
                                        </button>
                                    </td>
                                    <td>{student.schoolid}</td>
                                    <td>{student.firstname} {student.lastname}</td>
                                    <td>{student.nickname}</td>
                                    <td>{formatDate(student.date_of_birth)}</td>
                                    <td>{calculateAge(student.date_of_birth)}</td>
                                    <td>{student.email}</td>
                                    <td>{student.phone}</td>
                                    <td>{student.currenteducationlevel}</td>
                                    <td>{student.schoolname}</td>
                                    <td className="centered-content">
                                    {student.national_id_pdf && typeof student.national_id_pdf === 'string' ? (
                                        <a href={`${API_URL}/upload/national_ids/${student.national_id_pdf.split('/').pop()}`} target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={faFilePdf} size="lg" style={{ color: 'red' }} />
                                        </a>
                                    ) : (
                                        <span>{t('no_pdf')}</span>
                                    )}
                                    </td>

                                    <td>
                                        <div className="action-buttons">
                                            <button onClick={() => handleEdit(student.userid)} className="edit-button">
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                            <button onClick={() => handleDelete(student.userid)} className="delete-button">
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <PasswordModal
                isOpen={isPasswordModalOpen}
                onClose={() => setIsPasswordModalOpen(false)}
                onConfirm={confirmDelete}
                errorMessage={passwordError}
            />
            <EditStudentModal
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                onSave={saveStudent}
                studentId={studentIdToEdit}
            />
        </div>
    );
}

export default withRoleAccess(EditDeleteStudent, ['admin', 'superadmin']);
