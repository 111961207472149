import React, { useState, useEffect } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faChair } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../Dashboard.css';
import ClassroomModal from './ClassroomModal';
import ConfirmationModal from './ConfirmationModal';

function EditDeleteClassroom() {
    const { t } = useTranslation();
    const [classrooms, setClassrooms] = useState([]);
    const [showClassroomModal, setShowClassroomModal] = useState(false);
    const [currentClassroom, setCurrentClassroom] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchClassrooms = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${API_URL}/classrooms`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setClassrooms(response.data);
            } catch (error) {
                console.error('Error fetching classrooms:', error);
                setError(t('fetchClassroomsError'));
            } finally {
                setLoading(false);
            }
        };

        fetchClassrooms();
    }, [API_URL, t]);

    const handleEdit = (classroom) => {
        setCurrentClassroom(classroom);
        setShowClassroomModal(true);
    };

    const handleDelete = (classroomId) => {
        confirmAlert({
            title: t('confirmDelete'),
            message: t('areYouSureToDelete'),
            buttons: [
                {
                    label: t('yes'),
                    onClick: async () => {
                        try {
                            const token = localStorage.getItem('token');
                            await axios.delete(`${API_URL}/classrooms/${classroomId}`, {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            });
                            setClassrooms(prev => prev.filter(classroom => classroom.classroomid !== classroomId));
                            setConfirmationMessage(t('classroomDeleted'));
                            setShowConfirmationModal(true);
                        } catch (error) {
                            console.error('Error deleting classroom:', error);
                            setError(t('deleteClassroomError'));
                        }
                    }
                },
                {
                    label: t('no')
                }
            ]
        });
    };

    const handleSaveClassroom = async (classroomData) => {
        try {
            const token = localStorage.getItem('token');
            let response;
            if (currentClassroom) {
                // Editing existing classroom
                response = await axios.put(`${API_URL}/classrooms/${currentClassroom.classroomid}`, classroomData, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setClassrooms(prev => prev.map(classroom => classroom.classroomid === response.data.classroomid ? response.data : classroom));
                setConfirmationMessage(t('classroomUpdated'));
            } else {
                // Creating new classroom
                response = await axios.post(`${API_URL}/classrooms`, classroomData, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setClassrooms(prev => [...prev, response.data]);
                setConfirmationMessage(t('classroomCreated'));
            }
            setShowClassroomModal(false);
            setShowConfirmationModal(true);
        } catch (error) {
            console.error('Error saving classroom:', error);
            setError(t('saveClassroomError'));
        }
    };

    const closeConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    if (loading) return <p>{t('loading')}</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="classroomtable-container">
          <div className="admincontainer">
            <button className="create-classroom-button" onClick={() => { setCurrentClassroom(null); setShowClassroomModal(true); }}>
              <FontAwesomeIcon icon={faChair} /> {t('createNewClassroom')}
            </button>
            {showClassroomModal && (
              <ClassroomModal 
                isOpen={showClassroomModal}
                onClose={() => setShowClassroomModal(false)}
                onSave={handleSaveClassroom}
                classroomData={currentClassroom}
              />
            )}
            <ConfirmationModal
              isOpen={showConfirmationModal}
              message={confirmationMessage}
              onClose={closeConfirmationModal}
            />
            <div className="task-list-container">
              <table className="task-list-table">
                <thead>
                  <tr>
                    <th>{t('number')}</th>
                    <th>{t('capacity')}</th>
                    <th>{t('availability')}</th>
                    <th>{t('actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {[...classrooms]
                    .sort((a, b) => {
                      const numA = a.number.match(/\d+/g);
                      const numB = b.number.match(/\d+/g);
      
                      if (numA && numB) {
                        return parseInt(numA[0], 10) - parseInt(numB[0], 10); // Numeric sort if both have numbers
                      } else if (!numA && !numB) {
                        return a.number.localeCompare(b.number); // Alphabetical sort if both are letters
                      } else {
                        return numA ? -1 : 1; // Put numbers before letters
                      }
                    })
                    .map((classroom) => (
                      <tr key={classroom.classroomid}>
                        <td>{classroom.number}</td>
                        <td>{classroom.capacity}</td>
                        <td>{classroom.availability ? t('available') : t('notAvailable')}</td>
                        <td>
                          <div className="action-buttons">
                            <button onClick={() => handleEdit(classroom)} className="edit-button">
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button onClick={() => handleDelete(classroom.classroomid)} className="delete-button">
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
      
}

export default withRoleAccess(EditDeleteClassroom, ['admin', 'superadmin']);
