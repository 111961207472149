import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Chat.css';
import withRoleAccess from '../../hoc/withRoleAccess';
import getUserIdFromToken from '../../Utils/authUtils'; 

const ChatWindow = ({ selectedUserId }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const API_URL = process.env.REACT_APP_API_URL;

  const messagesEndRef = useRef(null); 

  // Fetch the user ID from the token
  const currentUserId = getUserIdFromToken();

  useEffect(() => {
    if (!selectedUserId) return;

    const fetchMessages = async () => {
      try {

        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/chat/${currentUserId}/${selectedUserId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setMessages(response.data);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, [currentUserId, selectedUserId, API_URL]);

  // Function to scroll to the bottom of the messages
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Scroll to the bottom whenever messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const sendMessage = async () => {
    if (!newMessage.trim()) return;

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_URL}/chat`, {
        sender_id: currentUserId, 
        receiver_id: selectedUserId,
        content: newMessage
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setMessages([...messages, response.data]);
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendMessage();  
    }
  };

  return (
    <div className="chat-window">
      <div className="chat-messages">
        {messages.map(msg => (
          <div key={msg.chatid} className={`chat-message ${msg.sender_id === currentUserId ? 'sent' : 'received'}`}>
            <strong>{msg.sender_id === currentUserId ? 'You' : msg.sender_name}</strong>: {msg.content}
            {msg.sender_id === currentUserId && (
              <span className={`message-status ${msg.is_read ? 'read' : ''}`}>
                <span className="single-check"></span>
              </span>
            )}
          </div>
        ))}
        <div ref={messagesEndRef} /> {/* Invisible div to ensure scroll to bottom */}
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={newMessage}
          onChange={e => setNewMessage(e.target.value)}
          onKeyDown={handleKeyDown}  // Add the onKeyDown event listener
          placeholder="Write your message..."
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
};


export default withRoleAccess(ChatWindow, ['admin', 'superadmin', 'teacher']);