import React, { useState } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'; 
import SuccessModal from './SuccessModal';
import './CreateUserForm.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const initialFormData = {
  firstname: '',
  lastname: '',
  nickname: '',
  nationalid: '',
  date_of_birth: '',
  email: '',
  password: '',
  role: 'teacher',
  phone: '',
  address: '',
  bachelorDegree: '', 
  masterDegree: '', 
  doctoralDegree: '', 
  bachelorSchool: '', 
  masterSchool: '', 
  doctoralSchool: '', 
  specializeSubjects: '', 
  hoursRate: '', 
  schedule: '', 
  isPartTime: false,
  language: [],
};

function CreateTeacherForm({ onClose }) {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    ...initialFormData, 
    address: "The Planner Co., Ltd.", 
    phone: "0957262666",
  });
  
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'language') {
      const selectedLanguages = formData.language.includes(value)
        ? formData.language.filter(lang => lang !== value)
        : [...formData.language, value];
      setFormData(prevData => ({
        ...prevData,
        language: selectedLanguages
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value
      }));
    }
  };
  const generateRandomPassword = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < 8; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setFormData(prevData => ({
      ...prevData,
      password: password
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };
  
  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
  
  
      // Check if date_of_birth is provided; if not, set to null
      const formattedDate = formData.date_of_birth 
        ? new Date(formData.date_of_birth).toISOString().split('T')[0] 
        : null; // Set to null if not provided
  
      // Check if nationalid is provided; if not, set to null
      const nationalIdValue = formData.nationalid 
        ? formData.nationalid 
        : null; // Set to null if not provided
  
  
      const dataToSend = {
        ...formData,
        date_of_birth: formattedDate,
        nationalid: nationalIdValue, // Set the nationalid to null if not provided
        active: true,
        schedule: JSON.stringify(formData.schedule),
        language: formData.language,
        hoursRate: formData.hoursRate || 0, // Default to 0 if empty
      };
  
      // Attempt to send the data
      const response = await axios.post(`${API_URL}/users/create`, dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
  
  
      // Reset form and close modal if successful
      setFormData(initialFormData);
      setShowConfirmModal(false);
      setError(null);
      onClose();
  
    } catch (error) {
      // Log the entire error object for more details
      console.error('Error creating user:', error);
  
      // If the error has a response, log the response data, otherwise log the general error message
      const errorMessage = error.response ? error.response.data.message : 'Server error';
      
  
      // Show an alert with the error message
      confirmAlert({
        title: 'Error',
        message: errorMessage,
        buttons: [
          {
            label: 'OK',
            onClick: () => setError(errorMessage)
          }
        ]
      });
    }
  };
  
  

  return (
    <div className="form-page">
      <Sidebar />
      <Header />
      <div className="form-content">
        <h2>{t('form.add_new_teacher')}</h2>
        <form onSubmit={(e) => { e.preventDefault(); setShowConfirmModal(true); }} className="create-user-form">
          <div className="section">
            <h3>{t('form.teacher_details')}</h3>
            <div className="form-row">
            <div className="form-group">
                <label htmlFor="firstname">
                  {t('form.first_name')}
                  <span style={{ color: 'red' }}> *</span>
                </label>
                <input
                  type="text"
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleChange}
                  placeholder={t('form.first_name')}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="lastname">{t('form.last_name')}<span style={{ color: 'red' }}> *</span></label>
                <input
                  type="text"
                  name="lastname" 
                  value={formData.lastname}
                  onChange={handleChange}
                  placeholder={t('form.last_name')}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="nickname">{t('form.nickname')}<span style={{ color: 'red' }}> *</span></label>
                <input
                  type="text"
                  name="nickname"
                  value={formData.nickname}
                  onChange={handleChange}
                  placeholder={t('form.nickname')}
                  required
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="email">{t('form.email')}<span style={{ color: 'red' }}> *</span></label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder={t('form.email')}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="phone">{t('form.phone')}<span style={{ color: 'red' }}> *</span></label>
                <textarea
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder={t('form.phone')}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">{t('form.temporary_password')}<span style={{ color: 'red' }}> *</span></label>
                <div className="password-container">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder={t('form.temporary_password')}
                    required
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? faEyeSlash : faEye}
                    className="password-toggle-icon"
                    onClick={togglePasswordVisibility}
                  />
                  <button className="generate-password-btn" type="button" onClick={generateRandomPassword}>
                    {t('form.generate_password')}
                  </button>
                </div>
              </div>

            </div>
            <div className="form-group">
              <label htmlFor="address">{t('form.address')}<span style={{ color: 'red' }}> *</span></label>
              <textarea
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder={t('form.address')}
                required
              />
            </div>
          </div>
          
          <div className="section">
            <h3>{t('form.academic_details')}</h3>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="bachelorDegree">{t('form.bachelor_degree')}<span style={{ color: 'red' }}> *</span></label>
                <input
                  type="text"
                  name="bachelorDegree"
                  value={formData.bachelorDegree}
                  onChange={handleChange}
                  placeholder={t('form.bachelor_degree')}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="bachelorSchool">{t('form.bachelor_school')}<span style={{ color: 'red' }}> *</span></label>
                <input
                  type="text"
                  name="bachelorSchool"
                  value={formData.bachelorSchool}
                  onChange={handleChange}
                  placeholder={t('form.bachelor_school')}
                  required
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="masterDegree">{t('form.master_degree')}</label>
                <input
                  type="text"
                  name="masterDegree"
                  value={formData.masterDegree}
                  onChange={handleChange}
                  placeholder={t('form.master_degree')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="masterSchool">{t('form.master_school')}</label>
                <input
                  type="text"
                  name="masterSchool"
                  value={formData.masterSchool}
                  onChange={handleChange}
                  placeholder={t('form.master_school')}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="doctoralDegree">{t('form.doctoral_degree')}</label>
                <input
                  type="text"
                  name="doctoralDegree"
                  value={formData.doctoralDegree}
                  onChange={handleChange}
                  placeholder={t('form.doctoral_degree')}
                />
              </div>
              <div className="form-group">
                <label htmlFor="doctoralSchool">{t('form.doctoral_school')}</label>
                <input
                  type="text"
                  name="doctoralSchool"
                  value={formData.doctoralSchool}
                  onChange={handleChange}
                  placeholder={t('form.doctoral_school')}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="specializeSubjects">{t('form.specialized_subjects')}<span style={{ color: 'red' }}> *</span></label>
                <input
                  type="text"
                  name="specializeSubjects"
                  value={formData.specializeSubjects}
                  onChange={handleChange}
                  placeholder={t('form.specialized_subjects')}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="schedule">{t('form.about')}<span style={{ color: 'red' }}> *</span></label>
                <input
                  type="text"
                  name="schedule"
                  value={formData.schedule}
                  onChange={handleChange}
                  placeholder={t('form.about')}
                  required
                />
              </div>
            </div>
            <div className="form-row">
            <div className="form-group">
              <div className="language-container">
                <label>
                  <input
                    type="checkbox"
                    name="isPartTime"
                    checked={formData.isPartTime}
                    onChange={handleChange}
                    className="custom-checkbox-input"
                  />
                  {t('form.part_time')}
                </label>
              </div>
            </div>
            <div className="form-group">
              <div className="language-container">
                <label>{t('form.languages')}: </label>
                <label>
                  <input
                    type="checkbox"
                    name="language"
                    value="Thai"
                    checked={formData.language.includes('Thai')}
                    onChange={handleChange}
                  />
                  Thai
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="language"
                    value="English"
                    checked={formData.language.includes('English')}
                    onChange={handleChange}
                  />
                  English
                </label>
              </div>
            </div>

          </div>
          </div>
          
          <button type="submit" className="submit-button">{t('form.create_teacher')}</button>
          {error && <div className="error-message">{t(error)}</div>}
        </form>
        {showConfirmModal && (
          <SuccessModal
            details={formData}
            onClose={() => setShowConfirmModal(false)}
            onConfirm={handleSubmit}
          />
        )}
      </div>
    </div>
  );
}

export default withRoleAccess(CreateTeacherForm, ['superadmin', 'admin']);