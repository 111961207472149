import React from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

const CustomPieChartStudent = ({ data }) => {
    const { t } = useTranslation();
    const totalClasses = data.length;
    const completedClasses = data.filter(cls => cls.status === 'Present' || cls.status === 'Late').length;
  
    const chartData = [
      { name: t('finished'), value: completedClasses , color: '#8dc572' },
      { name: t('remaining'), value: totalClasses - completedClasses, color: '#dcdcdc' }
    ];
  
    return (
      <PieChart width={200} height={200}>
        <Pie
          data={chartData}
          cx="50%"
          cy="50%"
          innerRadius={80}
          outerRadius={100}
          dataKey="value"
        >
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip />
        <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
          {`${Math.round((completedClasses / totalClasses) * 100)}%`}
        </text>
      </PieChart>
    );
  };
  

export default CustomPieChartStudent;
