import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
import axios from 'axios';
import getUserIdFromToken from '../Utils/authUtils';

const CustomPieChart = ({ teacherId }) => {
  const [data, setData] = useState([]);

  // Use teacherId prop if provided; otherwise, use userId from the token
  const actualTeacherId = teacherId || getUserIdFromToken();
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchRatings = async () => {
      if (!actualTeacherId) {
        console.error('Teacher ID is not defined');
        return;
      }

      try {
        // Retrieve the token from local storage
        const token = localStorage.getItem('token');

        // Include the Authorization header with the token
        const response = await axios.get(`${API_URL}/ratings/teacherrating/${actualTeacherId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setData(response.data); // Assuming response.data is already formatted as [{ name, value, color }]
      } catch (error) {
        console.error('Error fetching teacher ratings:', error);
      }
    };

    fetchRatings();
  }, [actualTeacherId, API_URL]); // Dependencies array to refetch if teacherId changes

  // Calculate total value for percentage calculation
  const total = data.reduce((sum, item) => sum + item.value, 0);

  return (
    <div>
      {data.length === 0 ? (
        <div>No data available</div>
      ) : (
        <div>
          <PieChart width={200} height={200}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={80} // Adjust inner radius for the donut effect
              outerRadius={100} // Adjust outer radius to control size
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
            <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
              Performance
            </text>
          </PieChart>

          {/* Display percentages below the chart */}
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            {data.map((entry, index) => (
              <div key={index}>
                {entry.name}: {(entry.value / total * 100).toFixed(2)}%
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomPieChart;
