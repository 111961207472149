import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import '../Dashboard.css';
import withRoleAccess from '../../hoc/withRoleAccess';

const EditStudentModal = ({ isOpen, onClose, onSave, studentId }) => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        phone: '',
        address: '',
        email: '',
        photourl: '',
        currenteducationlevel: '',
        guardiancontact: '',
        schoolname: '',
        schoolid: '',
        nickname: '',
        date_of_birth: '',
        nationalid: '',
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if (studentId) {
            const fetchStudent = async () => {
                try {
                    const token = localStorage.getItem('token');
                    const response = await axios.get(`${API_URL}/students/${studentId}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    setFormData(response.data);
                } catch (error) {
                    console.error('Error fetching student:', error);
                    setError(t('fetchStudentError'));
                } finally {
                    setLoading(false);
                }
            };

            fetchStudent();
        }
    }, [studentId, API_URL, t]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData);
    };

    if (!isOpen) return null;

    return (
        <div className="panel-overlay">
          <div className="modal-content">
            <span className="modal-close-icon" onClick={onClose}>&times;</span>
                <h2>{t('editStudent')}</h2>
                {loading ? (
                    <p>{t('loading')}</p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('firstName')}:</label>
                                <input type="text" name="firstname" value={formData.firstname} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('lastName')}:</label>
                                <input type="text" name="lastname" value={formData.lastname} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('email')}:</label>
                                <input type="email" name="email" value={formData.email} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('nickname')}:</label>
                                <input type="text" name="nickname" value={formData.nickname} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('dateOfBirth')}:</label>
                                <input type="date" name="date_of_birth" value={formData.date_of_birth} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('nationalID')}:</label>
                                <input type="text" name="nationalid" value={formData.nationalid} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('phone')}:</label>
                                <input type="text" name="phone" value={formData.phone} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('address')}:</label>
                                <input type="text" name="address" value={formData.address} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label>{t('photoURL')}:</label>
                                <input type="text" name="photourl" value={formData.photourl} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('currentEducationLevel')}:</label>
                                <input type="text" name="currenteducationlevel" value={formData.currenteducationlevel} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="form-row">

                            <div className="form-group">
                                <label>{t('guardianContact')}:</label>
                                <input type="text" name="guardiancontact" value={formData.guardiancontact} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>{t('schoolName')}:</label>
                                <input type="text" name="schoolname" value={formData.schoolname} onChange={handleChange} />
                            </div>
                        </div>

                        <div className="modal-actions">
                            <button type="button" className="cancel-button" onClick={onClose}>{t('cancel')}</button>
                            <button type="submit">{t('save')}</button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default withRoleAccess(EditStudentModal, ['admin', 'superadmin']);
