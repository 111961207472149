import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import withRoleAccess from '../../hoc/withRoleAccess';
import './Accounting.css';


const RefundModal = ({ show, onHide, registration, onRefund }) => {
  const { t } = useTranslation();
  const [refundAmount, setRefundAmount] = useState(registration.final_price);
  const [refundReason, setRefundReason] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  const handleRefund = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    const refundData = {
      registrationId: registration.id,
      refundAmount,
      refundReason,
      userId: registration.student_id,
    };

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_URL}/registrations/refund`, refundData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        onRefund(response.data);  // Pass the response data to the parent component
        onHide(); // Hide the modal before showing the confirmation alert
        setTimeout(() => {
          confirmAlert({
            title: t('refundSuccessful'),
            message: t('refundProcessedMessage'),
            buttons: [
              {
                label: t('ok'),
                onClick: () => setIsSubmitting(false)
              }
            ]
          });
        }, 300); // Slight delay to ensure the modal is completely hidden
      } else {
        alert(t('refundError'));
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Error processing refund:', error);
      alert(t('refundError'));
      setIsSubmitting(false);
    }
  };

  const confirmRefund = () => {
    setTimeout(() => {
      confirmAlert({
        title: t('confirmRefund'),
        message: t('confirmRefundMessage'),
        buttons: [
          {
            label: t('yes'),
            onClick: handleRefund
          },
          {
            label: t('no'),
            onClick: () => setIsSubmitting(false)
          }
        ]
      });
    }, 300); // Slight delay to ensure the modal is completely hidden
  };

  return (
    <>
      <div className={`panel-overlay${show ? ' show' : ''}`} style={{ display: show ? 'block' : 'none' }}>
        <div className="right-panel-popup">
          <div className="modal-header">
            <h2 className="modal-title">{t('processRefund')}</h2>
            <button type="button" className="close-button" onClick={onHide}>&times;</button>
          </div>
          <div className="modal-body">
            <div>
              <label>{t('refundAmount')}:</label>
              <input
                type="number"
                value={refundAmount}
                onChange={(e) => setRefundAmount(e.target.value)}
                max={registration.final_price}
                min="0"
                step="0.01"
              />
            </div>
            <div>
              <label>{t('refundReason')}:</label>
              <textarea
                value={refundReason}
                onChange={(e) => setRefundReason(e.target.value)}
                placeholder={t('refundReasonPlaceholder')}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={() => { onHide(); confirmRefund(); }} disabled={isSubmitting}>
              {t('refund')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRoleAccess(RefundModal, ['admin', 'superadmin']);
