import React, { } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Adjust the path based on your file structure

const RequireAuth = ({ children }) => {
  let { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    // Redirect them to the /signin page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // back to that page after they log in, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/signin" replace />;
  }

  return children;
};

export default RequireAuth; // Default export