import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import './Classes.css';

const StarRating = ({ rating, onRatingChange }) => {
  return (
    <div className="star-rating">
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;
        return (
          <label key={index}>
            <input
              type="radio"
              name="rating"
              value={ratingValue}
              onClick={() => onRatingChange(ratingValue)}
            />
            <FontAwesomeIcon
              icon={faStar}
              className="star"
              color={ratingValue <= rating ? '#ffc107' : '#e4e5e9'}
              size="2x"
            />
          </label>
        );
      })}
    </div>
  );
};

export default StarRating;
