import React, { createContext, useContext, useState, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [permissions, setPermissions] = useState({});

  // Define signOut first since other functions depend on it
  const signOut = useCallback(() => {
    setIsAuthenticated(false);
    setUser(null);
    setPermissions({});
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  }, []); // No dependencies needed for signOut

  const refreshToken = useCallback(async () => {
    try {
      const currentRefreshToken = localStorage.getItem('refreshToken');
      if (!currentRefreshToken) {
        throw new Error('No refresh token available');
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Refresh-Token': currentRefreshToken
        }
      });

      if (response.ok) {
        const { accessToken } = await response.json();
        localStorage.setItem('token', accessToken);
        return accessToken;
      } else {
        throw new Error('Failed to refresh token');
      }
    } catch (error) {
      console.error('Token refresh failed:', error);
      signOut();
      throw error;
    }
  }, [signOut]); // Include signOut in dependencies

  const signIn = useCallback(async (token) => {
    try {
      if (typeof token !== 'string' || token.trim() === '') {
        throw new Error('Invalid token specified');
      }

      const decodedToken = jwtDecode(token);
      const { role, ...rest } = decodedToken;

      setIsAuthenticated(true);
      setUser(rest);
      setPermissions({ role });
      localStorage.setItem('token', token);
    } catch (error) {
      console.error('Sign in failed:', error);
      signOut();
      throw error;
    }
  }, [signOut]); // Include signOut in dependencies

  const value = {
    user,
    isAuthenticated,
    permissions,
    signIn,
    signOut,
    refreshToken
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};