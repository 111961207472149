import React, { useState, useEffect } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faSchool } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../Dashboard.css';
import SubjectModal from './SubjectModal';
import ConfirmationModal from './ConfirmationModal';

function EditDeleteSubject() {
    const { t } = useTranslation();
    const [subjects, setSubjects] = useState([]);
    const [showSubjectModal, setShowSubjectModal] = useState(false);
    const [currentSubject, setCurrentSubject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchSubjects = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${API_URL}/subjects`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                // Sort the subjects by subjectid in ascending order
                const sortedSubjects = response.data.sort((a, b) => a.subjectid - b.subjectid);
                setSubjects(sortedSubjects);
            } catch (error) {
                console.error('Error fetching subjects:', error);
                setError(t('fetchSubjectsError'));
            } finally {
                setLoading(false);
            }
        };
    
        fetchSubjects();
    }, [API_URL, t]);
    

    const handleEdit = (subject) => {
        setCurrentSubject(subject);
        setShowSubjectModal(true);
    };

    const handleDelete = (subjectId) => {
        confirmAlert({
            title: t('confirmDelete'),
            message: t('areYouSureToDelete'),
            buttons: [
                {
                    label: t('yes'),
                    onClick: async () => {
                        try {
                            const token = localStorage.getItem('token');
                            await axios.delete(`${API_URL}/subjects/${subjectId}`, {
                                headers: {
                                    Authorization: `Bearer ${token}`
                                }
                            });
                            setSubjects(prev => prev.filter(subject => subject.subjectid !== subjectId));
                            setConfirmationMessage(t('subjectDeleted'));
                            setShowConfirmationModal(true);
                        } catch (error) {
                            console.error('Error deleting subject:', error);
                            setError(t('deleteSubjectError'));
                        }
                    }
                },
                {
                    label: t('no')
                }
            ]
        });
    };

    const handleSaveSubject = async (subjectData) => {
        try {
            const token = localStorage.getItem('token');
            let response;
            if (currentSubject) {
                response = await axios.put(`${API_URL}/subjects/${currentSubject.subjectid}`, subjectData, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setSubjects(prev => prev.map(subject => subject.subjectid === response.data.subjectid ? response.data : subject));
                setConfirmationMessage(t('subjectUpdated'));
            } else {
                response = await axios.post(`${API_URL}/subjects`, subjectData, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setSubjects(prev => [...prev, response.data]);
                setConfirmationMessage(t('subjectCreated'));
            }
            setShowSubjectModal(false);
            setShowConfirmationModal(true);
        } catch (error) {
            console.error('Error saving subject:', error);
            setError(t('saveSubjectError'));
        }
    };

    const closeConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    if (loading) return <p>{t('loading')}</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className="subjecttable-container">
            <div className="admincontainer">
                {showSubjectModal && (
                    <div className="subject-modal-backdrop">
                        <div className="subject-modal">
                            <div className="section">
                                <h2>{currentSubject ? t('editSubject') : t('createSubject')}</h2>
                                <button className="modal-close-button" onClick={() => setShowSubjectModal(false)}>×</button>
                            </div>
                            <div className="modal-body">
                                <SubjectModal 
                                    isOpen={showSubjectModal}
                                    onClose={() => setShowSubjectModal(false)}
                                    onSave={handleSaveSubject}
                                    subjectData={currentSubject}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <ConfirmationModal
                    isOpen={showConfirmationModal}
                    message={confirmationMessage}
                    onClose={closeConfirmationModal}
                />
                <div className="task-list-container">
                    <button className="create-subject-button" onClick={() => { setCurrentSubject(null); setShowSubjectModal(true); }}>
                        <FontAwesomeIcon icon={faSchool} /> {t('createSubject')}
                    </button>
                    <table className="task-list-table">
                        <thead>
                            <tr>
                                <th>{t('code')}</th>
                                <th>{t('group')}</th>
                                <th>{t('subjecttitle')}</th>
                                <th>{t('level')}</th>
                                <th>{t('specification')}</th>
                                <th>{t('description')}</th>
                                <th>{t('actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {subjects.map((subject) => (
                                <tr key={subject.subjectid}>
                                    <td>{subject.code}</td>
                                    <td>{subject.subjectgroup}</td>
                                    <td>{subject.title}</td>
                                    <td>{subject.level}</td>
                                    <td>{subject.specification}</td>
                                    <td>{subject.description}</td>
                                    <td>
                                        <div className="action-buttons">
                                            <button onClick={() => handleEdit(subject)} className="edit-button">
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                            <button onClick={() => handleDelete(subject.subjectid)} className="delete-button">
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default withRoleAccess(EditDeleteSubject, ['admin', 'superadmin']);
