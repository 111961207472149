import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import '../../Pages/Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faLink } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert'; // Import react-confirm-alert
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const AnnouncementAdmin = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [link, setLink] = useState('');
  const [by, setBy] = useState('Admin'); // Hardcoded admin name
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [imageUrl, setImageUrl] = useState(''); // New state for image URL
  const [editId, setEditId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchAnnouncements = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/announcements`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setAnnouncements(response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching announcements:', error);
      setError('Failed to fetch announcements. Please try again later.');
      setLoading(false);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchAnnouncements();
  }, [fetchAnnouncements]);

  const handleSaveAnnouncement = async (announcementData) => {
    const token = localStorage.getItem('token');
    try {
      let response;
      if (editId) {
        response = await axios.put(`${API_URL}/announcements/${editId}`, announcementData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAnnouncements(prev => prev.map(announcement => announcement.id === response.data.id ? response.data : announcement).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));
        confirmAlert({
          title: 'Success',
          message: 'Announcement updated successfully',
          buttons: [
            {
              label: 'OK',
              onClick: () => {},
            }
          ]
        });
      } else {
        response = await axios.post(`${API_URL}/announcements`, announcementData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAnnouncements(prev => [response.data, ...prev].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)));
        confirmAlert({
          title: 'Success',
          message: 'Announcement created successfully',
          buttons: [
            {
              label: 'OK',
              onClick: () => {},
            }
          ]
        });
      }
    } catch (error) {
      console.error('Error saving announcement:', error);
      setError('Failed to save announcement.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const announcementData = { title, message, link, by, date, image_url: imageUrl }; // Include image URL
    
    // Use react-confirm-alert to confirm the action
    confirmAlert({
      title: 'Confirm Announcement',
      message: 'Are you sure you want to create this announcement?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            handleSaveAnnouncement(announcementData);
            setTitle('');
            setMessage('');
            setLink('');
            setBy('Admin');
            setDate(new Date().toISOString().split('T')[0]);
            setImageUrl(''); // Clear image URL
            setEditId(null);
          }
        },
        {
          label: 'No',
          onClick: () => {} // Do nothing on cancel
        }
      ]
    });
  };

  const handleEdit = (announcement) => {
    setTitle(announcement.title);
    setMessage(announcement.message);
    setLink(announcement.link);
    setBy(announcement.by);
    setDate(new Date(announcement.date).toISOString().split('T')[0]);
    setImageUrl(announcement.image_url); // Set image URL
    setEditId(announcement.id);
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${API_URL}/announcements/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAnnouncements(prev => prev.filter(announcement => announcement.id !== id));
      confirmAlert({
        title: 'Success',
        message: 'Announcement deleted successfully',
        buttons: [
          {
            label: 'OK',
            onClick: () => {},
          }
        ]
      });
    } catch (error) {
      console.error('Error deleting announcement:', error);
    }
  };

  if (loading) {
    return <p>Loading announcements...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="announcement-admin-container">
      <div className="announcement-form-container">
        <h2>{editId ? 'Edit Announcement' : 'Create Announcement'}</h2>
        <form onSubmit={handleSubmit} className="announcement-form">
          <input
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <textarea
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
          <input
            type="text"
            placeholder="Link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
          <input
            type="text"
            placeholder="Image URL" // New input for image URL
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
          />
          <input
            type="text"
            placeholder="Admin Name"
            value={by}
            onChange={(e) => setBy(e.target.value)}
            required
          />
          <input
            type="date"
            placeholder="Date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
          <button type="submit" className="submit-button">
            {editId ? 'Update' : 'Add'} Announcement
          </button>
        </form>
      </div>
      <div className="announcement-list-container">
        <h2>Announcements</h2>
        <table className="announcement-table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Message</th>
              <th>Link</th>
              <th>By</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {announcements.map((announcement) => (
              <tr key={announcement.id}>
                <td>{announcement.title}</td>
                <td>{announcement.message}</td>
                <td>
                  {announcement.link && (
                    <a href={announcement.link} target="_blank" rel="noopener noreferrer" className="announcement-link">
                      <FontAwesomeIcon icon={faLink} />
                    </a>
                  )}
                </td>
                <td>{announcement.by}</td>
                <td>{new Date(announcement.date).toLocaleDateString()}</td>
                <td>
                  <div className="action-buttons">
                    <button onClick={() => handleEdit(announcement)} className="edit-button action-button">
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button onClick={() => handleDelete(announcement.id)} className="delete-button action-button">
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default withRoleAccess(AnnouncementAdmin, ['admin', 'superadmin']);
