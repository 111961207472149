import React, { useState } from 'react';
import withRoleAccess from '../../hoc/withRoleAccess';
import '../Dashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

function ClassroomModal({ onClose, onSave, classroomData }) {
  const { t } = useTranslation();
  const [classroom, setClassroom] = useState({
    number: classroomData?.number || '',
    capacity: classroomData?.capacity || '',
    availability: classroomData?.availability || true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClassroom({ ...classroom, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(classroom); // Pass the classroom data up to the parent component for processing
    onClose(); // Close the modal
  };

  return (
    <div className="classroom-modal-backdrop">
      <div className="classroom-modal">
        <div
          className="modal-close-icon"
          onClick={onClose}
          onKeyDown={(e) => { if(e.key === 'Enter') onClose(); }}
          tabIndex="0"
          role="button"
          aria-label={t('close')}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <form onSubmit={handleSubmit}>
          <label>
            {t('number')}
            <input type="text" name="number" value={classroom.number} onChange={handleChange} />
          </label>
          <label>
            {t('capacity')}
            <input type="number" name="capacity" value={classroom.capacity} onChange={handleChange} />
          </label>
          <label>
            {t('availability')}
            <select name="availability" value={classroom.availability} onChange={handleChange}>
              <option value={true}>{t('available')}</option>
              <option value={false}>{t('notAvailable')}</option>
            </select>
          </label>
          <div className="modal-actions">
            <button type="submit">{t('save')}</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default withRoleAccess(ClassroomModal, ['admin', 'superadmin']);
