import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../Dashboard.css';

const PasswordModal = ({ isOpen, onClose, onConfirm, errorMessage }) => {
    const { t } = useTranslation();
    const [password, setPassword] = useState('');

    useEffect(() => {
        if (errorMessage) {
            setPassword(''); // Clear password if there's an error message
        }
    }, [errorMessage]);

    if (!isOpen) return null;

    const handleConfirm = () => {
        onConfirm(password);
    };

    return (
        <div className="panel-overlay">
            <div className="right-panel-popup">
                <h2>{t('confirmDeletion')}</h2>
                <p>{t('enterPasswordToDelete')}</p>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={t('password')}
                />
                {errorMessage && <p className="error-message">{t(errorMessage)}</p>}
                <div className="modal-actions">
                    <button onClick={onClose}>{t('cancel')}</button>
                    <button onClick={handleConfirm}>{t('confirm')}</button>
                </div>
            </div>
        </div>
    );
};

export default PasswordModal;
