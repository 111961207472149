import React from 'react';
import AccessDeniedModal from '../components/AccessDeniedModal';

function withRoleAccess(WrappedComponent, allowedRoles) {
  return class extends React.Component {
    state = {
      showModal: false
    };

    componentDidMount() {
      const role = localStorage.getItem('role');
      if (!allowedRoles.includes(role)) {
        this.setState({ showModal: true });
      }
    }

    handleClose = () => {
      this.setState({ showModal: false });
    };

    render() {
      if (allowedRoles.includes(localStorage.getItem('role'))) {
        return <WrappedComponent {...this.props} />;
      } else {
        return <AccessDeniedModal isOpen={this.state.showModal} onClose={this.handleClose} />;
      }
    }
  };
}

export default withRoleAccess;
