import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { 
  Layout, 
  Table, 
  Modal, 
  Button, 
  Input, 
  Select, 
  DatePicker, 
  Space, 
  notification, 
  Card,
  Row,
  Col,
  Tag
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import './Registrations.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment-timezone';

const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

const testTypes = [
  { name: "GED", subtypes: ["Mathematical Reasoning", "Reasoning Through Language Arts", "Science", "Social Studies"] },
  { name: "IGCSE", subtypes: ["Mathematics", "English", "Sciences (Biology, Chemistry, Physics)", "History", "Geography", "Foreign Languages", "Art & Design", "Business Studies", "Computer Science"] },
  { name: "A-LEVEL", subtypes: ["Mathematics", "Further Mathematics", "Physics", "Chemistry", "Biology", "English Literature", "History", "Geography", "Economics", "Psychology"] },
  { name: "SAT", subtypes: ["SAT (General)", "SAT Subject Tests"] },
  { name: "IELTS", subtypes: ["IELTS Academic", "IELTS General Training"] },
  { name: "AP", subtypes: ["AP Calculus AB", "AP Calculus BC", "AP Physics", "AP Chemistry", "AP Biology", "AP English Language and Composition", "AP English Literature and Composition", "AP U.S. History", "AP World History", "AP Psychology"] },
  { name: "IB", subtypes: ["IB Mathematics", "IB Physics", "IB Chemistry", "IB Biology", "IB English A", "IB English B", "IB History", "IB Economics", "IB Psychology", "Theory of Knowledge (TOK)"] },
  { name: "TOEFL", subtypes: ["TOEFL iBT", "TOEFL PBT"] },
  { name: "TOEFL-MUIC", subtypes: ["General", "IBT"] },
  { name: "CU-AAT", subtypes: ["ธรรมดา", "E-Testing"] },
  { name: "CU-ATS", subtypes: ["Physics", "Chemistry"] },
  { name: "CU-TEP", subtypes: ["ธรรมดา + Speaking", "E-Testing", "E-Testing + CU-TEP E-Testing Speaking"] },
  { name: "TU-GET", subtypes: ["PBT", "CBT"] },
  { name: "OTHERS", subtypes: ["OTHERS"] }
];
const getDifferenceInDays = (date1, date2) => {
  const diffTime = Math.abs(date2 - date1);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
};

const BookTest = () => {
  const { t, i18n } = useTranslation();
  const [bookings, setBookings] = useState([]);
  const [students, setStudents] = useState([]);
  const [shownTests, setShownTests] = useState([]);
  const [formData, setFormData] = useState({
    student_id: '',
    test_type: '',
    sub_type: '',
    test_date: '',
    test_details: '',
    price: '',
    paid_status: 'unpaid',
  });
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(100);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [dateRange, setDateRange] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const fetchBookings = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/test-scores/test-bookings`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const currentDate = new Date();

      const sortedBookings = response.data.sort((a, b) => new Date(a.test_date) - new Date(b.test_date));
      const futureBookings = sortedBookings.filter(booking => new Date(booking.test_date) >= currentDate);

      setBookings(futureBookings);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  }, [API_URL]);

  useEffect(() => {
    const today = moment().tz("Asia/Bangkok").startOf('day');
    const upcomingTests = bookings.filter(booking => {
      const testDate = moment(booking.test_date).tz("Asia/Bangkok").startOf('day');
      const daysDifference = testDate.diff(today, 'days');

      // Check if the test is exactly 1 day away
      return daysDifference === 1 && !shownTests.includes(booking.id);
    });

    // Show toast notifications for upcoming tests
    upcomingTests.forEach(test => {
      toast.info(`${test.nickname} has a ${test.test_type} test scheduled for ${moment(test.test_date).format('DD MMM YYYY')}.`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      // Add the test ID to shownTests to avoid duplicate notifications
      setShownTests(prev => [...prev, test.id]);
    });
  }, [bookings, shownTests]);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    try {
      return moment.tz(dateString, 'Asia/Bangkok')
        .format('DD MMM YYYY')
        .toUpperCase();
    } catch {
      return 'Invalid date';
    }
  };
  const fetchStudents = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/students`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setStudents(response.data.students);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchBookings();
    fetchStudents();
  }, [fetchBookings, fetchStudents]);

  const getFilteredData = useCallback(() => {
    let filteredData = bookings;
  
    if (searchText) {
      filteredData = filteredData.filter(booking => {
        const fullName = `${booking.firstname} ${booking.lastname} ${booking.nickname}`.toLowerCase();
        return fullName.includes(searchText.toLowerCase());
      });
    }
  
  
    return filteredData;
  }, [bookings, searchText]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchText]);

  const disabledDate = (current) => {
    const today = moment().startOf('day');
    return current && current.startOf('day').isBefore(today);
  };

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      
      const submissionData = {
        ...formData,
        test_date: formData.test_date 
          ? moment(formData.test_date).format('YYYY-MM-DD')
          : null,
      };
  
      await axios.post(`${API_URL}/test-scores/book-test`, submissionData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      setFormData({
        student_id: '',
        test_type: '',
        sub_type: '',
        test_date: '',
        test_details: '',
        price: '',
        paid_status: 'unpaid'
      });
      
      setIsModalVisible(false);
      fetchBookings();
      notification.success({ message: t('Test booking submitted successfully') });
    } catch (error) {
      console.error('Error booking test:', error);
      setError(t('Error booking test'));
    }
  };

  const handleStatusUpdate = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${API_URL}/test-scores/update-status/${id}`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      fetchBookings();
      notification.success({ message: t('Status updated successfully') });
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const columns = [
    {
      title: t('Test Date'),
      dataIndex: 'test_date',
      key: 'test_date',
      render: formatDate,
      sorter: (a, b) => {
        const dateA = moment.tz(a.test_date, 'Asia/Bangkok');
        const dateB = moment.tz(b.test_date, 'Asia/Bangkok');
        return dateA.valueOf() - dateB.valueOf();
      }
    },
    { title: t('Student'), dataIndex: 'firstname', key: 'firstname', render: (_, record) => `${record.firstname} ${record.lastname}` },
    { title: t('Nickname'), dataIndex: 'nickname', key: 'nickname' },
    { title: t('Test'), dataIndex: 'test_type', key: 'test_type' },
    { title: t('Subject'), dataIndex: 'sub_type', key: 'sub_type' },
    { title: t('Price'), dataIndex: 'price', key: 'price' },
    { title: t('Details'), dataIndex: 'test_details', key: 'test_details' },
    {
      title: t('Status'),
      dataIndex: 'paid_status',
      key: 'paid_status',
      render: (status) => (
        <span style={{ color: status === 'paid' ? 'green' : 'red' }}>
          {status}
        </span>
      ),
    },
    {
      title: t('Update'),
      key: 'update',
      render: (_, record) => (
        record.paid_status === 'unpaid' && (
          <Button type="primary" onClick={() => handleStatusUpdate(record.id)}>
            {t('Mark as Paid')}
          </Button>
        )
      ),
    },
  ];

  return (
    <Layout>
      
      <Sidebar />
      <Layout>

        <Header />
        <Content>
                <ToastContainer
                        position="top-right"
                        autoClose={false}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick={false}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        />
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card>
                <Space direction="vertical" style={{ width: '100%' }} size="middle">
                  <Row gutter={16} align="middle">
                    <Col xs={24} sm={8}>
                      <Button type="primary" onClick={() => setIsModalVisible(true)}>
                        {t('Register for a Test')}
                      </Button>
                    </Col>
                    <Col xs={24} sm={8}>
                      <Input
                        placeholder={t('Search by student name or nickname')}
                        prefix={<SearchOutlined />}
                        value={searchText}
                        onChange={e => setSearchText(e.target.value)}
                        allowClear
                      />
                    </Col>
                  </Row>
                </Space>
              </Card>
            </Col>
          </Row>
          <Modal
            title={t('Register for a Test')}
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            onOk={handleSubmit}
          >
            <form>
              <div className="form-group">
                <label>{t('Student')}</label>
                <Select
                  value={formData.student_id}
                  onChange={(value) => setFormData({ ...formData, student_id: value })}
                  placeholder={t('Select Student')}
                  style={{ width: '100%' }}
                >
                  {students.map(student => (
                    <Option key={student.userid} value={student.userid}>
                      {`${student.firstname} ${student.lastname} (${student.nickname})`}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="form-group">
                <label>{t('Test Type')}</label>
                <Select
                  value={formData.test_type}
                  onChange={(value) => setFormData({ ...formData, test_type: value })}
                  placeholder={t('Select Test Type')}
                  style={{ width: '100%' }}
                >
                  {testTypes.map(type => (
                    <Option key={type.name} value={type.name}>
                      {type.name}
                    </Option>
                  ))}
                </Select>
              </div>
              {formData.test_type && (
                <div className="form-group">
                  <label>{t('Subject')}</label>
                  <Select
                    value={formData.sub_type}
                    onChange={(value) => setFormData({ ...formData, sub_type: value })}
                    placeholder={t('Select Sub Type')}
                    style={{ width: '100%' }}
                  >
                    {testTypes.find(type => type.name === formData.test_type)?.subtypes.map((subtype, index) => (
                      <Option key={index} value={subtype}>
                        {subtype}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
                  <div className="form-group">
                  <label>{t('Test Date')}</label>
                  <DatePicker
                      value={formData.test_date ? moment(formData.test_date) : null}
                      onChange={(date) => setFormData({ 
                        ...formData, 
                        test_date: date ? date.format('YYYY-MM-DD') : null 
                      })}
                      style={{ width: '100%' }}
                      disabledDate={disabledDate}
                    />
                </div>
              <div className="form-group">
                <label>{t('Test Details')}</label>
                <Input.TextArea
                  value={formData.test_details}
                  onChange={(e) => setFormData({ ...formData, test_details: e.target.value })}
                  rows={4}
                  placeholder={t('Enter test details')}
                />
              </div>
              <div className="form-group">
                <label>{t('Price')}</label>
                <Input
                  type="number"
                  value={formData.price}
                  onChange={(e) => setFormData({ ...formData, price: e.target.value })}
                  placeholder={t('Enter price')}
                />
              </div>
              <div className="form-group">
                <label>{t('Paid Status')}</label>
                <Select
                  value={formData.paid_status}
                  onChange={(value) => setFormData({ ...formData, paid_status: value })}
                  style={{ width: '100%' }}
                >
                  <Option value="unpaid">{t('Unpaid')}</Option>
                  <Option value="paid">{t('Paid')}</Option>
                </Select>
              </div>
              {error && <div className="error-message">{error}</div>}
            </form>
          </Modal>
          <Table
            columns={columns}
            dataSource={getFilteredData()}
            pagination={{
              current: currentPage,
              pageSize: itemsPerPage,
              total: getFilteredData().length,
              onChange: setCurrentPage,
            }}
            rowKey="id"
          />
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRoleAccess(BookTest, ['admin', 'superadmin']);
