import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import withRoleAccess from '../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';

const formatMinutesToHours = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h ${remainingMinutes}m`;
};

const MonthlyHoursTrends = ({ teacherId }) => {
  const { t } = useTranslation();
  const [hoursData, setHoursData] = useState([]);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchMonthlyHours = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/classes/teacher/monthlyhours/${teacherId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setHoursData(response.data);
      } catch (error) {
        console.error('Error fetching monthly hours:', error);
        setError(t('fetchMonthlyHoursError'));
      }
    };

    fetchMonthlyHours();
  }, [teacherId, API_URL, t]);

  if (error) return <p>{error}</p>;

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={hoursData}
        margin={{
          top: 20, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis tickFormatter={(value) => `${Math.floor(value / 60)}h`} />
        <Tooltip 
          formatter={(value) => formatMinutesToHours(value)}
          contentStyle={{ backgroundColor: '#8884d8', color: '#fff' }}
          labelFormatter={(label) => `${t('month')}: ${label}`}
        />
        <Legend />
        <Bar dataKey="total_hours" fill="#82ca9d">
          {hoursData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.month === 'Jul' ? '#8884d8' : '#82ca9d'} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default withRoleAccess(MonthlyHoursTrends, ['admin', 'superadmin', 'teacher']);
