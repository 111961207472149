import React, { useEffect, useRef, useState, useCallback } from 'react';
import './Accounting.css';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';

const ReceiptModal = ({ show, onClose, registration }) => {
  const { t, i18n } = useTranslation();
  const componentRef = useRef();
  const [totalAmount, setTotalAmount] = useState(0);

  const formatNumber = (number, locale) => {
    if (isNaN(number)) {
      return '0.00';
    }
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);
  };

  const formatDate = (dateString, locale) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString(locale, options).toUpperCase();
  };

  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hr')}`;
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // Updated calculateTotalAmount to sum class price and inventory items
  const calculateTotalAmount = useCallback(() => {
    const classPrice = parseFloat(registration?.price) || 0;
    const inventoryTotal = (registration?.inventory_items || []).reduce(
      (sum, item) => {
        const parsedItem = JSON.parse(item); // Parse each inventory item if it's stored as a string
        return sum + parseFloat(parsedItem.price_per_unit || 0); // Use price_per_unit for calculation
      },
      0
    );
    return classPrice + inventoryTotal; // Return the total before VAT
  }, [registration]);

  useEffect(() => {
    if (show && registration) {
      setTotalAmount(calculateTotalAmount());
    }
  }, [show, registration, calculateTotalAmount]);

  if (!show) {
    return null;
  }

  return (
    <div className="invoice-modal-overlay">
      <div className="invoice-modal" ref={componentRef}>
        {/* Display watermark if the status is 'refund' */}
        {registration.confirmation_status === 'refund' && (
          <div className="refund-watermark">
            {t('refund')}
          </div>
        )}
        <span className="modal-close-button" onClick={onClose}>&times;</span>
        
        <div className="invoice-header">
          <div className="invoice-header-left">
            <img
              src="/images/Theplanner.png"
              alt="The Planner Education"
              className="invoice-logo"
              style={{ width: '100px', height: 'auto' }} 
            />
            <div className="company-info">
              <p><strong>THE PLANNER EDUCATION</strong></p>
              <p>The Mercury Ville @Chidlom 3rd floor</p>
              <p>Lumpini Pathumwan Bangkok</p>
              <p>www.theplannereducation.com</p>
              <p>Tel. 02-253-2533 or 095-726-2666 </p>
              <p>Line: @theplanner</p>
            </div>
          </div>

          <div className="invoice-header-right">
            <h3>{t('receipt')}</h3>
            <p>{t('receiptNo')}: {registration.invoiceno.replace('INV', 'RE')}</p>
            <p>{t('date')}: {formatDate(registration.date, i18n.language)}</p>
          </div>
        </div>

        <div className="bill-to">
          <h3>{t('Receivedfrom')}:</h3>
          <p>{t('name')}: {registration.student_name}</p>
          <p>{t('class')}: {registration.class_code}</p>
          <p>{t('hours')}: {formatMinutesToHours(registration.total_hours)}</p>
        </div>

        {/* Updated receipt table */}
        <div className="invoice-table">
          <table>
            <thead>
              <tr>
                <th>{t('description')}</th>
                <th>{t('quantity')}</th>
                <th>{t('amount')}</th>
              </tr>
            </thead>
            <tbody>
              {/* Class Price Row */}
              <tr>
                <td>{registration.class_code} {formatMinutesToHours(registration.total_hours)}</td>
                <td>1</td>
                <td>{formatNumber(registration.price, i18n.language)}</td>
              </tr>

              {/* Inventory Items Rows */}
              {registration.inventory_items && registration.inventory_items.map((item, index) => {
                const parsedItem = JSON.parse(item); // Parse each inventory item if it's stored as a string
                return (
                  <tr key={index}>
                    <td>{parsedItem.label}</td>
                    <td>1</td>
                    <td>{formatNumber(parsedItem.price_per_unit, i18n.language)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* Summary section */}
        <div className="invoice-summary">
          <div className="summary-item">
            <span>{t('total')}:</span>
            <span>{formatNumber(totalAmount, i18n.language)}</span> {/* Total before VAT */}
          </div>
          <div className="summary-item">
            <span>{t('discount')}:</span>
            <span>{formatNumber(-registration.discount, i18n.language)} THB</span>
          </div>
          <div className="summary-item">
            <span>{t('vat', { percentage: 7 })}:</span>
            <span>{formatNumber(registration.vat, i18n.language)} THB</span>
          </div>
          <div className="summary-item grand-total">
            <span><strong>{t('grandTotal')}:</strong></span>
            <span><strong>{formatNumber(registration.final_price, i18n.language)} THB</strong></span>
          </div>
        </div>

        <div className="payment-details">
          <p>{t('paymentMethod')}: {registration.payment_method}</p>
          <p>{t('memo')}: {registration.memo || t('na')}</p>
          <p>{t('paymentConfirmed')}</p>
          
          <div><p><i>บริษัทขอสงวนสิทธิ์ในการคืนเงินทุกกรณี / No refunds under any circumstances.</i></p></div>
        </div>
        {registration.signature && (
          <div className="signature">
            <p>{t('signature')}:</p>
            <img src={registration.signature} alt="Signature" />
          </div>
        )}
        <div className="invoice-actions">
          <button onClick={handlePrint}>{t('printReceipt')}</button>
        </div>
      </div>
    </div>
  );
};

export default ReceiptModal;
