import React, { useEffect, useState } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { CSVLink } from 'react-csv';
import './Teacher.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const generateMonthYearOptions = () => {
  const options = [];
  const startYear = 2024;
  const startMonth = 7; // August (0-indexed, so January is 0, August is 7)
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  for (let year = startYear; year <= currentYear + 5; year++) {
    for (let month = 0; month < 12; month++) {
      if (year === startYear && month < startMonth) continue;
      const monthYear = new Date(year, month).toLocaleString('default', {
        month: 'short',
        year: 'numeric',
      }).toUpperCase();
      options.push({ value: `${year}-${month + 1}`, label: monthYear });
      if (year === currentYear && month >= currentMonth) break;
    }
  }
  return options;
};

const TutorClassLog = () => {
  const { t } = useTranslation();
  const [classes, setClasses] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  
  // Get current year and month
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const defaultMonthYear = `${currentYear}-${currentMonth}`;

  const [selectedMonthYear, setSelectedMonthYear] = useState(defaultMonthYear); // Set default
  const [statusFilter, setStatusFilter] = useState('');
  const [adminapproveFilter, setAdminapproveFilter] = useState('');
  const [payoutFilter, setPayoutFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageGroup, setPageGroup] = useState(0);
  const itemsPerPage = 150;
  const pagesPerGroup = 10;

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/classes`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setClasses(response.data);
        setFilteredClasses(response.data); // Initially display all classes
      } catch (error) {
        console.error('Error fetching classes:', error);
      }
    };
    fetchClasses();
  }, []);

  useEffect(() => {
    const filtered = classes.filter(cls => {
      const classDate = new Date(cls.date);
      const classMonthYear = `${classDate.getFullYear()}-${classDate.getMonth() + 1}`;
      const isMonthYearMatch = selectedMonthYear ? classMonthYear === selectedMonthYear : true;
      const isStatusMatch = statusFilter ? cls.status === statusFilter : true;
      const isAdminapproveMatch = adminapproveFilter ? cls.adminapprove === adminapproveFilter : true;
      const isPayoutMatch = payoutFilter ? cls.payout === payoutFilter : true;
      return isMonthYearMatch && isStatusMatch && isAdminapproveMatch && isPayoutMatch;
    });

    setFilteredClasses(filtered);
    setCurrentPage(1); // Reset to first page after filtering
  }, [selectedMonthYear, statusFilter, adminapproveFilter, payoutFilter, classes]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentClasses = filteredClasses.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredClasses.length / itemsPerPage);
  const totalPageGroups = Math.ceil(totalPages / pagesPerGroup);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePrevGroup = () => {
    if (pageGroup > 0) {
      setPageGroup(pageGroup - 1);
      setCurrentPage(pageGroup * pagesPerGroup);
    }
  };

  const handleNextGroup = () => {
    if (pageGroup < totalPageGroups - 1) {
      setPageGroup(pageGroup + 1);
      setCurrentPage(pageGroup * pagesPerGroup + 1);
    }
  };

  const monthYearOptions = generateMonthYearOptions();

  const csvData = filteredClasses.map(cls => ({
    No: cls.classid,
    Date: new Date(cls.date).toLocaleDateString(),
    ClassCode: cls.class_code,
    ClassID: cls.classid,
    Subject: cls.subject_name,
    Teacher: cls.teacher_name,
    Time: cls.schedule_time,
    ScheduleHour: cls.schedule_hour,
    Status: cls.status,
    AdminApprove: cls.adminapprove,
    Payout: cls.payout,
  }));

  return (
    <div className="teacher-hours">
      <h2>{t('tutorclasslogs')}</h2>

      <div className="filter-container">
        <select
          value={selectedMonthYear}
          onChange={(e) => setSelectedMonthYear(e.target.value)}
          className="filter-select"
        >
          <option value="">{t('All Months/Years')}</option>
          {monthYearOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="filter-select"
        >
          <option value="">{t('classstatuses')}</option>
          <option value="approved">{t('confirmed')}</option>
          <option value="pending">{t('confirmpending')}</option>
        </select>

        <select
          value={adminapproveFilter}
          onChange={(e) => setAdminapproveFilter(e.target.value)}
          className="filter-select"
        >
          <option value="">{t('adminapprove')}</option>
          <option value="adminconfirm">{t('Admin Confirmed')}</option> 
          <option value="pending">{t('Pending')}</option>
        </select>

        <select
            value={payoutFilter}
            onChange={(e) => setPayoutFilter(e.target.value)}
            className="filter-select"
          >
            <option value="">{t('payout')}</option>
            <option value="pending">{t('Pending')}</option>
            <option value="paid">{t('Paid')}</option>
          </select>
      </div>

  

      <CSVLink data={csvData} filename={`tutor_class_log_${selectedMonthYear || 'all'}.csv`}>
        <button><FontAwesomeIcon icon={faFileExcel} /> {t('exportToCSV')}</button>
      </CSVLink>

      <div className="pagination-container">
        <button onClick={handlePrevGroup} disabled={pageGroup === 0} className="page-button">
          {t('Previous 10 Pages')}
        </button>
        {Array.from({ length: pagesPerGroup }, (_, index) => {
          const pageNumber = pageGroup * pagesPerGroup + index + 1;
          if (pageNumber > totalPages) return null;
          return (
            <button
              key={pageNumber}
              onClick={() => handlePageChange(pageNumber)}
              className={`page-button ${currentPage === pageNumber ? 'active' : ''}`}
            >
              {pageNumber}
            </button>
          );
        })}
        <button onClick={handleNextGroup} disabled={pageGroup === totalPageGroups - 1} className="page-button">
          {t('Next 10 Pages')}
        </button>
      </div>
      <div style={{ overflowX: 'auto', maxWidth: '1290px'}}>
      <table>
        <thead>
          <tr>
            <th>{t('No')}</th>
            <th>{t('date')}</th>
            <th>{t('classcode')}</th>
            <th>{t('classid')}</th>
            <th>{t('subject')}</th>
            <th>{t('teacher')}</th>
            <th>{t('time')}</th>
            <th>{t('scheduleHour')}</th>
            <th>{t('confirmteach')}</th>
            <th>{t('adminapprove')}</th>
            <th>{t('payout')}</th>
          </tr>
        </thead>
        <tbody>
          {currentClasses.map((cls, index) => (
            <tr key={cls.classid}>
              <td>{startIndex + index + 1}</td>
              <td>{new Date(cls.date).toLocaleDateString()}</td>
              <td>{cls.class_code}</td>
              <td>{cls.classid}</td>
              <td>{cls.subject_name}</td>
              <td>({cls.nickname}) {cls.teacher_name}</td>
              <td>{cls.schedule_time}</td>
              <td>{cls.schedule_hour}</td>
              <td className={cls.status === 'approved' ? 'status-approved' : 'status-pending'}>
                {cls.status === 'approved' ? t('HOURSAPPROVED') : t('confirmpending')}
              </td>
              <td className={cls.adminapprove === 'adminconfirm' ? 'admin-status-approved' : 'admin-status-pending'}>
                {cls.adminapprove === 'adminconfirm' ? t('adminconfirmed') : t('confirmpending')}
              </td>
              <td className={cls.payout === 'pending' ? 'payout-pending' : 'payout-paid'}>
                  {cls.payout === 'pending' ? t('Pending') : t('Paid')}
                </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default withRoleAccess(TutorClassLog, ['superadmin', 'admin']);