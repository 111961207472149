import React, { useState, useRef } from 'react';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUndo, faPen, faEraser, faSave, faPaintBrush, faPalette } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import './DoodlePage.css';

const DoodlePage = () => {
  const canvasRef = useRef(null);
  const { t } = useTranslation();
  const [strokeColor, setStrokeColor] = useState("#000000");
  const [strokeWidth, setStrokeWidth] = useState(4);
  const [isErasing, setIsErasing] = useState(false);

  const saveImage = async () => {
    const dataUrl = await canvasRef.current.exportImage("png");
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = "sketch.png";
    link.click();
  };

  const toggleEraser = () => {
    if (isErasing) {
      setStrokeColor("#000000"); // Reset to black
    } else {
      setStrokeColor("#FFFFFF"); // Set to white (assuming the background is white)
    }
    setIsErasing(!isErasing);
  };

  return (
    <div className="doodle-container">
      <Sidebar />
      <Header />
      <div className="doodle-content">
        <ReactSketchCanvas
          ref={canvasRef}
          style={{ border: '0.0625rem solid #9c9c9c', borderRadius: '0.25rem' }}
          width="1200px"
          height="650px"
          strokeWidth={strokeWidth}
          strokeColor={strokeColor}
          backgroundColor="white"
        />
        <div className="doodle-tools">
        <button onClick={() => canvasRef.current.clearCanvas()} className="doodle-btn">
                    <FontAwesomeIcon icon={faTrash} /> {t('clear')}
                    </button>
                    <button onClick={() => canvasRef.current.undo()} className="doodle-btn">
                    <FontAwesomeIcon icon={faUndo} /> {t('undo')}
                    </button>
                    <button onClick={toggleEraser} className="doodle-btn">
                    <FontAwesomeIcon icon={isErasing ? faPen : faEraser} /> {isErasing ? t('pen') : t('eraser')}
                    </button>
                    <button onClick={saveImage} className="doodle-btn">
                    <FontAwesomeIcon icon={faSave} /> {t('save')}
                    </button>
                    <div className="brush-options">
                    <label>
                        <FontAwesomeIcon icon={faPaintBrush} /> {t('pensize')}
                    </label>
                    <input
                        type="range"
                        min="1"
                        max="10"
                        value={strokeWidth}
                        onChange={(e) => setStrokeWidth(e.target.value)}
                    />
                    </div>
                    <div className="color-options">
                    <label>
                        <FontAwesomeIcon icon={faPalette} /> {t('pencolor')}
                    </label>
                    <input
                        type="color"
                        value={strokeColor}
                        onChange={(e) => {
                        setStrokeColor(e.target.value);
                        setIsErasing(false); // Disable eraser when changing color
                        }}
                    />
                    </div>

        </div>
      </div>
    </div>
  );
};  

export default withRoleAccess(DoodlePage, ['admin', 'superadmin', 'teacher', 'student']);